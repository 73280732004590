import AppleIcon from '../../assets/images/AppleIcon';
import GoogleIcon from '../../assets/images/GoogleIcon';
import HeaderView from '../../components/HeaderView';
import { useGoogleLogin } from '@react-oauth/google';
import { config } from '../../config/config';
import { lazy, Suspense } from 'react';
import api from '../../api';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Phone from '../../assets/icons/Phone';
import Link from '../../components/Link';
import { useForm } from 'react-hook-form';
import FormCheckboxesRequired from './FormCheckboxesRequired';
import classNames from 'classnames';
import GoogleSingleColorIcon from '../../assets/images/GoogleSingleColorIcon';

const AppleLogin = lazy(() => import('react-apple-login'));

enum LoginTypeProvider {
  google = 'google',
  apple = 'apple',
  phone = 'phone',
}

export type SetScreen = (
  screen: 'phone' | 'channel' | 'code' | 'routes',
) => void;

export type LoginProps = {
  setScreen: SetScreen;
};

function RoutesContainer({ setScreen }: LoginProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      terms: false,
      privacy: false,
    },
  });
  const { terms, privacy } = watch();

  const disabled = !terms || !privacy;

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const {
        data: { access_token: token },
      } = await api.socialLogin({
        accessToken: tokenResponse.access_token,
        provider: LoginTypeProvider.google,
      });
      localStorage.setItem('jwtToken', token);
      navigate('/home');
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  const responseApple = async (response: any) => {
    const { authorization, error } = response;
    if (error) {
      console.log(error);
      return;
    }
    const {
      data: { access_token: token },
    } = await api.socialLogin({
      accessToken: authorization.id_token,
      provider: LoginTypeProvider.apple,
    });
    localStorage.setItem('jwtToken', token);
    navigate('/home');
  };

  const getLocale = () => {
    const locale = localStorage.getItem('i18nextLng');
    switch (locale) {
      case 'en':
        return '?lang=en';
      case 'pt':
        return '?lang=pt';
      case 'es':
      default:
        return '';
    }
  };

  return (
    <div className="container max-w-[327px] pt-4 md:pt-0">
      <HeaderView
        title={t('login__header_title')}
        subtitle={t('login__channel_option_subtitle')}
      />
      <form className="flex flex-col w-full justify-center items-center mt-3">
        <FormCheckboxesRequired
          register={register}
          terms={terms}
          privacy={privacy}
          errors={errors}
        />

        <div className="flex flex-col gap-2 justify-center w-full">
          <ChannelButton handleClick={() => googleLogin()} disabled={disabled}>
            {disabled ? <GoogleSingleColorIcon /> : <GoogleIcon />}
            <span className="">{t('login__channel_option_button_google')}</span>
          </ChannelButton>
          <Suspense
            fallback={
              <div className="rounded-lg flex justify-center items-center">
                <AppleIcon className="text-black" />
              </div>
            }
          >
            <AppleLogin
              clientId={config.services.apple.clientId}
              redirectURI={window.location.origin}
              callback={responseApple}
              responseType="code id_token"
              usePopup={true}
              responseMode="form_post"
              scope="name email"
              render={(renderProps) => (
                <ChannelButton
                  handleClick={renderProps.onClick}
                  disabled={disabled}
                >
                  <AppleIcon />
                  <span>{t('login__channel_option_button_apple')}</span>
                </ChannelButton>
              )}
            />
          </Suspense>
          <ChannelButton
            handleClick={() => setScreen('phone')}
            disabled={disabled}
          >
            <Phone className="w-5 h-5" />
            <span>{t('login__channel_option_button_phone')}</span>
          </ChannelButton>
        </div>
      </form>
    </div>
  );
}

const ChannelButton = ({ handleClick, children, disabled }: any) => {
  return (
    <button
      className={classNames(
        'flex rounded-lg bg-gray-light uppercase p-4 font-medium tracking-[20%] gap-4 whitespace-nowrap text-sm transition-colors duration-200',
        disabled ? 'pointer-events-none text-[#D0CCDC]' : 'text-dark',
      )}
      type="button"
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
export default RoutesContainer;

import Button from '../../components/Button';
import Form from '../../components/Form';
import HeaderView from '../../components/HeaderView';
import { useForm } from 'react-hook-form';
import FourPointStar from '../../assets/icons/FourPointStar';
import api from '../../api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type PlanPremiumFormProps = {
  setScreen: (screen: 'form' | 'activated') => void;
};

function PlanPremiumForm({ setScreen }: PlanPremiumFormProps) {
  const { register, handleSubmit, getValues } = useForm({
    mode: 'onSubmit',
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = () => {
    const values = getValues();
    if (!values.plan_code) return;
    api
      .verifyCoupon({ code: values.plan_code })
      .then(() => setScreen('activated'))
      .catch(({ response }) => {
        setErrorMessage(response.data.message);
        setError(true);
      });
  };

  return (
    <>
      <div className="rounded-3xl mb-5 flex justify-center items-center bg-primary w-[60px] h-[60px]">
        <FourPointStar className="text-white" />
      </div>
      <HeaderView
        title={t('plan_premium__form_header_title')}
        subtitle={t('plan_premium__form_header_subtitle')}
      />
      <div className="max-w-md w-full justify-center">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <Form.Field
            type="text"
            name="plan_code"
            label={t('plan_premium__form_input_label')}
            placeholder={t('plan_premium__form_input_label')}
            register={register}
            required
          />
          {error && (
            <div className="mb-4 text-sm text-negative">
              {errorMessage ? errorMessage : t('plan_premium__form_error')}
            </div>
          )}
          <Button className="ml-auto " type="submit">
            {t('plan_premium__form_submit_button')}
          </Button>
        </form>
        <Button
          className="capitalize text-gray-dark"
          variant="custom"
          onClick={() => navigate('/home')}
        >
          {t('plan_premium__form_return')}
        </Button>
      </div>
    </>
  );
}

export default PlanPremiumForm;

export const config = {
  app: {
    environment: import.meta.env.VITE_APP_ENV,
  },
  mediquo: {
    api: import.meta.env.VITE_MEDIQUO_API_URL,
    chatApi: import.meta.env.VITE_CHAT_API_URL,
  },
  services: {
    google: {
      clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
      recaptcha_api_key: import.meta.env.VITE_GOOGLE_RECAPTCHA_API_KEY,
    },
    apple: {
      clientId: import.meta.env.VITE_APPLE_CLIENT_ID,
    },
    sentry: {
      environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
      dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    },
  },
};

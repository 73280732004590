import { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Loading from '../components/Loading';
import Login from '../pages/Login';
import PlanPremium from '../pages/PlanPremium';
import Profile from '../pages/Profile';
import Verify from '../pages/Verify';
import Agenda from '../pages/Agenda';
import ConsultationsHistory from '../pages/ConsultationsHistory';
import SelectCountry from '../pages/Country';

const RoutesIndex = () => {
  const country = localStorage.getItem('country_code');

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Loading />}>
            {country ? <Login /> : <SelectCountry />}
          </Suspense>
        }
      />
      <Route path="home" element={<Profile />} />
      <Route path="profile" element={<Verify />} />
      <Route path="agenda" element={<Agenda />} />
      <Route path="consultations_history" element={<ConsultationsHistory />} />
      <Route path="plan_premium" element={<PlanPremium />} />
      <Route path="*" element={<Navigate to="/" replace={true} />} />
    </Routes>
  );
};
export default RoutesIndex;

export default (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    {...props}
  >
    <path
      d="M12 2.52441L13.3015 7.73041C13.5555 8.74626 13.6824 9.25419 13.9469 9.6675C14.1808 10.0331 14.4913 10.3436 14.8569 10.5775C15.2702 10.842 15.7782 10.969 16.794 11.2229L22 12.5244L16.794 13.8259C15.7782 14.0799 15.2702 14.2069 14.8569 14.4713C14.4913 14.7053 14.1808 15.0157 13.9469 15.3813C13.6824 15.7946 13.5555 16.3026 13.3015 17.3184L12 22.5244L10.6985 17.3184C10.4445 16.3026 10.3176 15.7946 10.0531 15.3813C9.81915 15.0157 9.50868 14.7053 9.14309 14.4713C8.72978 14.2069 8.22185 14.0799 7.20599 13.8259L2 12.5244L7.20599 11.2229C8.22185 10.969 8.72978 10.842 9.14309 10.5775C9.50868 10.3436 9.81915 10.0331 10.0531 9.6675C10.3176 9.25419 10.4445 8.74626 10.6985 7.7304L12 2.52441Z"
      fill="#FFB801"
    />
    <path
      d="M12 2.52441L13.3015 7.73041C13.5555 8.74626 13.6824 9.25419 13.9469 9.6675C14.1808 10.0331 14.4913 10.3436 14.8569 10.5775C15.2702 10.842 15.7782 10.969 16.794 11.2229L22 12.5244L16.794 13.8259C15.7782 14.0799 15.2702 14.2069 14.8569 14.4713C14.4913 14.7053 14.1808 15.0157 13.9469 15.3813C13.6824 15.7946 13.5555 16.3026 13.3015 17.3184L12 22.5244L10.6985 17.3184C10.4445 16.3026 10.3176 15.7946 10.0531 15.3813C9.81915 15.0157 9.50868 14.7053 9.14309 14.4713C8.72978 14.2069 8.22185 14.0799 7.20599 13.8259L2 12.5244L7.20599 11.2229C8.22185 10.969 8.72978 10.842 9.14309 10.5775C9.50868 10.3436 9.81915 10.0331 10.0531 9.6675C10.3176 9.25419 10.4445 8.74626 10.6985 7.7304L12 2.52441Z"
      fill="#FFB801"
    />
  </svg>
);

import { Link, useSearchParams } from 'react-router-dom';
import {
  AgendaLayout,
  AgendaLayoutCard,
  AgendaLayoutMain,
  AgendaLayoutTitle,
} from '../Agenda/AgendaLayout';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import rqOptions from '../../rq/options';
import dayjs from 'dayjs';

const ArrowLeft = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 12H5M5 12L12 19M5 12L12 5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ConsultationsHistoryContainer = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const endOfYesterday = dayjs().subtract(1, 'day').endOf('day').toDate();

  const { data: appointments, isSuccess } = useQuery(
    rqOptions.appointments.getAll({
      page,
      starts_at_lte: endOfYesterday.toISOString(),
    }),
  );

  const toItems =
    appointments?.data.map(({ professional, speciality, appointment }) => {
      const date = new Date(appointment.starts_at);

      return {
        id: appointment.id,
        name: professional?.name,
        position: speciality?.name,
        date: dayjs(date).format('DD/MM/YY'),
        time: dayjs(date).format('HH:mm'),
        url: appointment.url,
      };
    }) ?? [];

  return (
    <AgendaLayout>
      <AgendaLayoutMain>
        <AgendaLayoutTitle className="md:w-full inline-flex items-center -translate-x-6 md:translate-x-0">
          <Link to="/agenda">
            <ArrowLeft className="mr-4" />
          </Link>
          {t('consultations_history_container__title')}
        </AgendaLayoutTitle>
        {isSuccess ? (
          <AgendaLayoutCard
            items={toItems}
            emptyStateText={t('consultations_history_container__empty_state')}
            pagination={{
              prevPageLink:
                page > 1
                  ? `/consultations_history?page=${page - 1}`
                  : undefined,
              nextPageLink:
                appointments.meta.current_page < appointments.meta.last_page
                  ? `/consultations_history?page=${page + 1}`
                  : undefined,
              page,
            }}
          />
        ) : null}
      </AgendaLayoutMain>
    </AgendaLayout>
  );
};

export default ConsultationsHistoryContainer;

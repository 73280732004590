import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';

export const usePatient = () => {
  const [profile, setProfile] = useState();
  const [error, setError] = useState<any>();
  const [subscription, setSubscription] = useState();
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setError(null);
    if (!profile) {
      getProfile();
    }
  }, [profile]);

  const getProfile = async () => {
    const {
      data: {
        data: { subscription, profile },
      },
    } = await api.profile();
    if (profile) setProfile(profile);
    if (subscription) setSubscription(subscription);
    const {
      first_name,
      last_name,
      phone,
      phone_prefix,
      email,
      birth_date,
      gender,
      billing,
    } = await profile;
    if (
      first_name &&
      last_name &&
      phone &&
      phone_prefix &&
      email &&
      birth_date &&
      billing &&
      billing?.tax_id &&
      gender
    ) {
      setIsProfileComplete(true);
    } else {
      setIsProfileComplete(false);
      navigate('/profile');
    }
  };

  const onUpdate = ({ formData, closure }: any) => {
    api.updateProfile({ formData }).then(() => {
      setIsProfileComplete(true);
      closure();
      getProfile();
    });
  };

  return { profile, subscription, error, isProfileComplete, onUpdate };
};

export default (...props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0C28.196 0 32 4.3877 32 16C32 27.6123 28.196 32 16 32C3.80401 32 0 27.6123 0 16C0 4.3877 3.80401 0 16 0Z"
      fill="#ECEEFD"
    />
    <path opacity="0.12" d="M14 22V16H18V22" fill="#0A0A22" />
    <path
      d="M14 22V17.0667C14 16.6933 14 16.5066 14.0727 16.364C14.1366 16.2386 14.2386 16.1366 14.364 16.0727C14.5066 16 14.6933 16 15.0667 16H16.9333C17.3067 16 17.4934 16 17.636 16.0727C17.7614 16.1366 17.8634 16.2386 17.9273 16.364C18 16.5066 18 16.6933 18 17.0667V22M15.3451 9.84267L10.8236 13.3594C10.5213 13.5945 10.3702 13.712 10.2614 13.8592C10.1649 13.9896 10.0931 14.1365 10.0494 14.2927C10 14.469 10 14.6605 10 15.0434V19.8667C10 20.6134 10 20.9868 10.1453 21.272C10.2732 21.5229 10.4771 21.7268 10.728 21.8547C11.0132 22 11.3866 22 12.1333 22H19.8667C20.6134 22 20.9868 22 21.272 21.8547C21.5229 21.7268 21.7268 21.5229 21.8547 21.272C22 20.9868 22 20.6134 22 19.8667V15.0434C22 14.6605 22 14.469 21.9506 14.2927C21.9069 14.1365 21.8351 13.9896 21.7386 13.8592C21.6298 13.712 21.4787 13.5945 21.1764 13.3594L16.6549 9.84267C16.4207 9.6605 16.3035 9.56941 16.1742 9.5344C16.0601 9.50351 15.9399 9.50351 15.8258 9.5344C15.6965 9.56941 15.5793 9.6605 15.3451 9.84267Z"
      stroke="#4A1EA7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default (props: any) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.0172 12.5555C18.0078 10.957 18.732 9.75234 20.1945 8.86406C19.3766 7.69219 18.1391 7.04766 16.5078 6.92344C14.9633 6.80156 13.2734 7.82344 12.6547 7.82344C12.0008 7.82344 10.5055 6.96563 9.32891 6.96563C6.90078 7.00313 4.32031 8.90156 4.32031 12.7641C4.32031 13.9055 4.52891 15.0844 4.94609 16.2984C5.50391 17.8969 7.51484 21.8133 9.6125 21.75C10.7094 21.7242 11.4852 20.9719 12.9125 20.9719C14.2977 20.9719 15.0148 21.75 16.2383 21.75C18.3547 21.7195 20.1734 18.1594 20.7031 16.5563C17.8648 15.218 18.0172 12.6375 18.0172 12.5555ZM15.5539 5.40703C16.7422 3.99609 16.6344 2.71172 16.5992 2.25C15.5492 2.31094 14.3352 2.96484 13.6437 3.76875C12.882 4.63125 12.4344 5.69766 12.5305 6.9C13.6648 6.98672 14.7008 6.40313 15.5539 5.40703Z"
      fill="currentColor"
    />
  </svg>
);

import axios from 'axios';
import { config } from '../config/config';
import getHeaders from '../utils/getHeaders';

export const chatApiAxiosInstance = axios.create({
  baseURL: config.mediquo.chatApi,
});

chatApiAxiosInstance.interceptors.request.use((config) => {
  const headers = getHeaders();

  Object.entries(headers).forEach(([key, value]) => {
    config.headers[key] = value;
  });

  return config;
});

import { chatApiAxiosInstance } from './axiosInstances';
import { PER_PAGE } from './constants';
import { PaginatedRequest } from './types';

export type GetAppointmentsRequest = PaginatedRequest & {
  starts_at_gte?: string;
  starts_at_lte?: string;
  status?: string;
};

export interface GetAppointmentsResponse {
  data: Array<{
    appointment: {
      id: string;
      url: string;
      starts_at: string;
      duration: number;
      status: string;
    };
    patient: {
      hash: string;
    };
    professional: {
      hash: string;
      name: string;
    };
    speciality: {
      id: string;
      name: string;
    };
  }>;
  meta: {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  };
}

const getAll = ({
  page,
  per_page = PER_PAGE,
  starts_at_gte,
  starts_at_lte,
  status,
}: GetAppointmentsRequest): Promise<GetAppointmentsResponse> =>
  chatApiAxiosInstance
    .get('appointments/v1/', {
      params: { page, per_page, starts_at_gte, starts_at_lte, status },
    })
    .then((e) => e.data);

export const appointmentsApi = {
  getAll,
};

import { useEffect, useState } from 'react';
import ProfileForm from '../../containers/ProfileForm';
import Loading from '../../components/Loading';
import LogoContainer from '../../components/LogoContainer';
import { usePatient } from '../../containers/usePatient';

const Verify = () => {
  const [screen, setScreen] = useState<string>('loading');
  const { profile, isProfileComplete, onUpdate } = usePatient();

  useEffect(() => {
    if (profile) {
      setScreen('verify');
    }
  }, [profile]);

  return (
    <div className="min-h-full h-full flex flex-col w-full">
      <LogoContainer
        hideButton
        setScreen={setScreen}
        isProfileComplete={isProfileComplete}
      />
      <div className="h-full flex flex-col justify-center items-center p-6">
        {
          {
            loading: <Loading />,

            verify: (
              <ProfileForm
                profile={profile}
                onUpdate={onUpdate}
                title="profile_form__title"
              />
            ),
          }[screen]
        }
      </div>
    </div>
  );
};

export default Verify;

import { useState } from 'react';
import LogoContainer from '../components/LogoContainer';
import AddPhoneContainer from '../containers/Login/AddPhoneContainer';
import ChannelContainer from '../containers/Login/ChannelContainer';
import CodeContainer from '../containers/Login/CodeContainer';
import RoutesContainer from '../containers/Login/RoutesContainer';

export type Phone = {
  prefix: string | any;
  number?: string;
  phone?: string;
  routes?: any;
};

function Login() {
  const [screen, setScreen] = useState<string>('routes');
  const [phone, setPhone] = useState<Phone>({ prefix: '', number: '' });

  return (
    <div className="min-h-full h-full flex flex-col w-full">
      <LogoContainer setScreen={setScreen} screen={screen} />
      <div className="h-full flex flex-col md:justify-center items-center p-6">
        {
          {
            routes: <RoutesContainer setScreen={setScreen} />,
            phone: (
              <AddPhoneContainer
                setScreen={setScreen}
                onSend={({ phone, prefix }) => {
                  setPhone({ prefix, number: phone });
                  setScreen('channel');
                }}
              />
            ),
            code: <CodeContainer setScreen={setScreen} phone={phone} />,
            channel: <ChannelContainer setScreen={setScreen} phone={phone} />,
          }[screen]
        }
      </div>
    </div>
  );
}

export default Login;

export default (props: any) => (
  <svg
    width="43"
    height="42"
    viewBox="0 0 43 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.6535 7.8181C20.9442 7.0325 22.0553 7.0325 22.346 7.81809L25.5337 16.4328C25.6251 16.6797 25.8199 16.8745 26.0668 16.9659L34.6815 20.1536C35.4671 20.4443 35.4671 21.5554 34.6815 21.8461L26.0668 25.0338C25.8199 25.1252 25.6251 25.32 25.5337 25.5669L22.346 34.1816C22.0553 34.9672 20.9442 34.9672 20.6535 34.1816L17.4658 25.5669C17.3744 25.32 17.1796 25.1252 16.9327 25.0338L8.318 21.8461C7.53241 21.5554 7.5324 20.4443 8.318 20.1536L16.9327 16.9659C17.1796 16.8745 17.3744 16.6797 17.4658 16.4328L20.6535 7.8181Z"
      fill="currentColor"
    />
  </svg>
);

import HeaderView from '../../components/HeaderView';
import CheckIcon from '../../assets/icons/CheckIcon';
import PhoneBackground from '../../assets/images/PhoneBackground';
import LogoIcon from '../../assets/icons/LogoIcon';
import Star from '../../assets/icons/Star';
import AppPatientQr from '../../assets/qr/AppPatientQr';
import isMobile from '../../utils/isMobile';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';

function PlanActivated() {
  const isMobileView = isMobile();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div className="rounded-3xl mb-5 text-white flex justify-center items-center bg-primary w-[60px] h-[60px]">
        <CheckIcon className="w-12 h-5" />
      </div>
      <HeaderView
        title={t('plan_premium__header_title')}
        subtitle={t('plan_premium__header_subtitle')}
      />
      <Button className="max-w-md mb-10" onClick={() => navigate('/home')}>
        {t('plan_premium__header_button')}
      </Button>

      <div className="max-w-md min-w-fit w-full justify-center items-center bg-background rounded-2xl">
        <div className="flex flex-row m-4 items-center justify-center gap-2">
          <div className="flex flex-col items-start justify-start">
            <div className="flex flex-row items-center justify-start gap-2">
              <div className="rounded-lg text-white flex justify-center items-center bg-primary w-10 h-10 min-h-[40px] min-w-[40px]">
                <LogoIcon />
              </div>
              <div className="flex flex-col text-dark text-xs items-start">
                <span>MediQuo</span>
                <div className="flex flex-row items-center">
                  {[...Array(5).keys()].map((index) => (
                    <Star key={index} className="text-dark" />
                  ))}
                  <span className="text-xs text-gray-dark">(4,5)</span>
                </div>
              </div>
            </div>
            {!isMobileView && (
              <div className="flex flex-col my-4 items-start w-full text-xs text-gray-dark">
                <span>{t('plan_premium__not_mobile_title')}</span>
                <span>{t('plan_premium__not_mobile_description')}</span>
              </div>
            )}
          </div>
          {isMobileView ? (
            <a
              className="bg-primary text-white py-2 px-4 rounded-xl uppercase text-sm tracking-widest"
              href="https://www.mediquo.com/app"
            >
              {t('plan_premium__mobile_link')}
            </a>
          ) : (
            <AppPatientQr />
          )}
        </div>
        {isMobileView && <PhoneBackground className="flex w-full" />}
      </div>
      {isMobileView && (
        <span className="text-gray-dark text-xs">
          {t('plan_premium__mobile_text')}
        </span>
      )}
    </>
  );
}

export default PlanActivated;

import LogoContainer from '../../components/LogoContainer';
import ConsultationsHistoryContainer from '../../containers/ConsultationsHistory/ConsultationsHistoryContainer';
import { usePatient } from '../../containers/usePatient';

const ConsultationsHistory = () => {
  const { isProfileComplete } = usePatient();

  return (
    <main className="">
      <LogoContainer hideButton isProfileComplete={isProfileComplete} />
      <ConsultationsHistoryContainer />
    </main>
  );
};

export default ConsultationsHistory;

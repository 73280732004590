import classnames from 'classnames';
import CheckIcon from '../../assets/icons/CheckIcon';
import AlertIcon from '../../assets/icons/AlertIcon';
import { useRef } from 'react';
import { Trans } from 'react-i18next';

const FormCheckboxesRequired = ({ register, terms, privacy, errors }: any) => {
  const getLocale = () => {
    const locale = localStorage.getItem('i18nextLng');
    switch (locale) {
      case 'en':
        return '?lang=en';
      case 'pt':
        return '?lang=pt';
      case 'es':
      default:
        return '';
    }
  };

  return (
    <div className="my-4 w-full">
      <FormCheckBox
        register={register}
        name="terms"
        value={terms}
        errors={errors?.terms}
      >
        <p className="text-gray-dark">
          <Trans i18nKey="login__phone_register_terms">
            Acepto los
            <FormCheckBox.Link
              href={`https://api.mediquo.com/api/terms${getLocale()}`}
            >
              términos y condiciones
            </FormCheckBox.Link>
          </Trans>
        </p>
      </FormCheckBox>
      <FormCheckBox
        value={privacy}
        register={register}
        name="privacy"
        errors={errors?.privacy}
      >
        <p className="text-gray-dark">
          <Trans i18nKey="login__phone_register_privacy">
            Acepto la
            <FormCheckBox.Link
              href={`https://api.mediquo.com/api/privacy${getLocale()}`}
            >
              política de privacidad
            </FormCheckBox.Link>
          </Trans>
        </p>
      </FormCheckBox>
    </div>
  );
};

const FormCheckBox = ({
  value,
  register,
  name,
  errors,
  children,
  className,
}: any) => {
  const checkboxRef = useRef();
  return (
    <label
      className={`flex text-dark text-xs pb-2 ${className}`}
      onMouseDown={(event) => {
        event.preventDefault();
        // @ts-ignore
        checkboxRef!.current?.click();
      }}
    >
      <input
        tabIndex="-1"
        ref={checkboxRef}
        {...register(name, { required: true })}
        name={name}
        type="checkbox"
        className="sr-only"
      />
      <div
        className={`${classnames({
          'bg-primary border-none': !!value,
          'bg-white': !value,
          'border-negative': errors,
        })} w-4 h-4 flex flex-shrink-0 items-center justify-center border border-gray-medium rounded focus:outline-none mr-2 cursor-pointer`}
      >
        {!!value && <CheckIcon className="text-white" />}
      </div>
      {children}
      {errors && <AlertIcon className="w-4 h-4" />}
    </label>
  );
};

const Link = ({ children, href }: any) => (
  <a
    target="_blank"
    rel="noreferrer"
    href={href}
    className="ml-1 font-medium text-primary"
  >
    {children}
  </a>
);

FormCheckBox.Link = Link;

export default FormCheckboxesRequired;

import { UseQueryOptions } from '@tanstack/react-query';
import { querykeys } from '../querykeys';

import api from '../../api';
import {
  GetAppointmentsRequest,
  GetAppointmentsResponse,
} from '../../api/appointments';

export const getAll = (
  params: GetAppointmentsRequest,
): UseQueryOptions<GetAppointmentsResponse> => ({
  queryKey: querykeys.appointments.getAll(params),
  queryFn: () => api.appointments.getAll(params),
});

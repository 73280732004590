import React, { useContext, createContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuid } from 'uuid';

const PortalContext = createContext();

export const usePortal = () => {
  const { addElement, removeElement, isOpen } = useContext(PortalContext);

  return {
    isOpen,
    open: addElement,
    close: removeElement,
  };
};

const PortalProvider = ({ children }) => {
  const [elements, setElements] = useState([]);

  const removeLast = (elements) => {
    const copy = [...elements];
    copy.pop();
    return copy;
  };

  const isOpen = (id) => elements.find((modal) => modal.id === id);

  const exist = (modals, id) => modals.find((modal) => modal.id === id);

  const addElement = (content, id) =>
    setElements((elements) =>
      exist(elements, id)
        ? elements
        : [...elements, { id: id || uuid(), content }],
    );

  const removeElement = (id) =>
    setElements((elements) =>
      ['string', 'number'].includes(typeof id) && id !== ''
        ? elements.filter((element) => element.id !== id)
        : removeLast(elements),
    );

  return (
    <PortalContext.Provider value={{ addElement, removeElement, isOpen }}>
      {children}
      {ReactDOM.createPortal(
        elements.map(({ content, id }) =>
          React.cloneElement(content, {
            ...content.props,
            key: id,
          }),
        ),
        document.body,
      )}
    </PortalContext.Provider>
  );
};

export default PortalProvider;

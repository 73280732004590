export default (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.2795 15.2213L13.4314 15.0773C14.0768 14.5058 15.6774 13.5719 16.6075 13.5719C17.5377 13.5719 17.9664 13.5512 18.4073 13.7446C18.8481 13.9379 18.9336 13.9655 18.9336 13.9655C20.2187 14.6698 21.012 15.5622 20.9999 16.7915V17.9472C20.9999 19.683 19.5464 21.1026 17.7964 20.9942C11.233 20.5864 3.41329 12.7689 3.00582 6.20305C2.89743 4.45334 4.31695 3 6.0528 3H7.20844C8.75836 3 10.0574 4.15728 10.2408 5.70068L10.4346 7.38461C10.5408 8.31054 9.41166 9.99064 8.75389 10.6484L8.73026 10.6721L13.2795 15.2213Z"
      fill="currentColor"
    />
  </svg>
);

import { useEffect, useState } from 'react';
import api from '../../api';
import ProfileForm from '../../containers/ProfileForm';
import Loading from '../../components/Loading';
import LogoContainer from '../../components/LogoContainer';
import { usePatient } from '../../containers/usePatient';
import ProfileContainer from '../../containers/Profile';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const [screen, setScreen] = useState<string>('loading');
  const { profile, subscription, isProfileComplete } = usePatient();
  const navigate = useNavigate();

  useEffect(() => {
    if (profile) {
      isProfileComplete ? setScreen('profile') : navigate('/profile');
    }
  }, [profile]);

  return (
    <div className="min-h-full h-full flex flex-col w-full">
      <LogoContainer
        hideButton
        setScreen={setScreen}
        isProfileComplete={isProfileComplete}
      />
      <div className="h-full flex flex-col justify-center items-center p-6">
        {
          {
            loading: <Loading />,
            profile: (
              <ProfileContainer profile={profile} subscription={subscription} />
            ),
          }[screen]
        }
      </div>
    </div>
  );
};

export default Profile;

export default (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6101 23.3773C23.3087 23.7734 22.8467 24.0004 22.342 24.0004C22.1062 24.0004 21.9188 23.951 21.7682 23.9113L21.7682 23.9113L21.7675 23.9111L20.3166 23.532C19.2359 23.2499 18.1579 22.9684 17.079 22.6853C15.464 23.4573 13.7555 23.8486 11.9945 23.8486C11.2112 23.8486 10.4064 23.7703 9.60304 23.6163C6.47501 23.0182 3.67073 21.1057 1.90919 18.3695C0.147914 15.6337 -0.43019 12.2928 0.323075 9.20344C1.64158 3.76992 6.31695 0.0708955 11.9565 0.000391328L11.9862 0L12.0156 0.000586992C12.8118 0.0211317 13.5638 0.0977667 14.2511 0.228144C18.0447 1.00017 21.1386 3.42901 22.7587 6.89604C24.3233 10.2444 24.2599 14.0191 22.5966 17.3104C23.0132 18.7916 23.4215 20.2905 23.8372 21.821L23.8373 21.8213C23.9056 22.0666 24.0935 22.7422 23.6101 23.3773ZM19.9595 8.20069C18.7575 5.6283 16.4594 3.82571 13.6546 3.25489C13.1658 3.16234 12.5758 3.10247 11.9659 3.08538C7.77387 3.15028 4.30341 5.89831 3.32452 9.93166C2.77025 12.2059 3.20126 14.6727 4.50724 16.7014C5.81354 18.7305 7.88283 20.1469 10.1843 20.587C12.2501 20.9829 14.2048 20.7073 16.0219 19.7655L16.1029 19.7264C16.506 19.5463 16.9206 19.497 17.3972 19.5843L17.5114 19.6097C18.4166 19.8473 19.3213 20.084 20.2268 20.3205C19.9931 19.4681 19.7597 18.6257 19.5235 17.7904L19.5019 17.7057C19.376 17.1392 19.4494 16.626 19.7267 16.1372C21.0671 13.6656 21.1573 10.7636 19.9595 8.20069ZM13.3504 15.8498V13.4661H15.8C16.5881 13.4661 17.2315 12.8234 17.2315 12.0357C17.2315 11.2484 16.5881 10.6058 15.8 10.6058H13.3504V8.22185C13.3504 7.41357 12.6862 6.7502 11.8769 6.7502C11.0677 6.7502 10.4034 7.41357 10.4034 8.22185V10.6058H7.95412C7.16586 10.6058 6.52201 11.2484 6.52201 12.0357C6.52201 12.8234 7.16586 13.4661 7.95412 13.4661H10.4034V15.8498C10.4034 16.6582 11.0677 17.3217 11.8769 17.3217C12.6862 17.3217 13.3504 16.6582 13.3504 15.8498Z"
      fill="currentColor"
    />
  </svg>
);

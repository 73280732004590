import HeaderView from '../../components/HeaderView';
import PhoneForm from '../../containers/Login/PhoneForm';
import { Phone } from '../../pages/Login';
import { useTranslation } from 'react-i18next';
import { SetScreen } from './RoutesContainer';

export type LoginProps = {
  setScreen: SetScreen;
  onSend: (phone: Phone) => void;
};

function AddPhoneContainer({ setScreen, onSend }: LoginProps) {
  const { t } = useTranslation();

  return (
    <div className="container max-w-[440px] pt-4 md:pt-0">
      <HeaderView title={t('login__header_title')} />
      <div className="w-full mt-16">
        <PhoneForm
          onSend={onSend}
          onGoBack={() => setScreen('routes')}
          onReturn={() => setScreen('routes')}
        />
      </div>
      <div className="flex flex-col w-full justify-center items-center mt-4 gap-8"></div>
    </div>
  );
}

export default AddPhoneContainer;

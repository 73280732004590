import { config } from '../config/config';

export default () => ({
  Authorization:
    localStorage.getItem('jwtToken') &&
    `Bearer ${localStorage.getItem('jwtToken')}`,
  'Accept-Language': 'es',
  'X-Mdq-Origin':
    config.app.environment === 'production' ? 'mdq-console' : 'mdq-console-dev',
  'X-Mdq-Trace-Id': localStorage.getItem('sessionId'),
});

export default (props: any) => (
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_455_2644)">
      <g clipPath="url(#clip0_455_2644)">
        <path d="M112 8H16V104H112V8Z" fill="white" />
        <mask
          id="mask0_455_2644"
          //style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="19"
          y="11"
          width="90"
          height="90"
        >
          <path
            d="M20.9921 42.1759C21.8404 42.1759 22.5281 41.4882 22.5281 40.6399C22.5281 39.7916 21.8404 39.1039 20.9921 39.1039C20.1437 39.1039 19.4561 39.7916 19.4561 40.6399C19.4561 41.4882 20.1437 42.1759 20.9921 42.1759Z"
            fill="white"
          />
          <path
            d="M20.9921 45.2479C21.8404 45.2479 22.5281 44.5602 22.5281 43.7119C22.5281 42.8636 21.8404 42.1759 20.9921 42.1759C20.1437 42.1759 19.4561 42.8636 19.4561 43.7119C19.4561 44.5602 20.1437 45.2479 20.9921 45.2479Z"
            fill="white"
          />
          <path
            d="M20.9921 48.3199C21.8404 48.3199 22.5281 47.6322 22.5281 46.7839C22.5281 45.9356 21.8404 45.2479 20.9921 45.2479C20.1437 45.2479 19.4561 45.9356 19.4561 46.7839C19.4561 47.6322 20.1437 48.3199 20.9921 48.3199Z"
            fill="white"
          />
          <path
            d="M20.9921 54.464C21.8404 54.464 22.5281 53.7763 22.5281 52.928C22.5281 52.0797 21.8404 51.392 20.9921 51.392C20.1437 51.392 19.4561 52.0797 19.4561 52.928C19.4561 53.7763 20.1437 54.464 20.9921 54.464Z"
            fill="white"
          />
          <path
            d="M20.9921 57.5359C21.8404 57.5359 22.5281 56.8482 22.5281 55.9999C22.5281 55.1516 21.8404 54.4639 20.9921 54.4639C20.1437 54.4639 19.4561 55.1516 19.4561 55.9999C19.4561 56.8482 20.1437 57.5359 20.9921 57.5359Z"
            fill="white"
          />
          <path
            d="M20.9921 63.6799C21.8404 63.6799 22.5281 62.9922 22.5281 62.1439C22.5281 61.2956 21.8404 60.6079 20.9921 60.6079C20.1437 60.6079 19.4561 61.2956 19.4561 62.1439C19.4561 62.9922 20.1437 63.6799 20.9921 63.6799Z"
            fill="white"
          />
          <path
            d="M20.9921 66.7519C21.8404 66.7519 22.5281 66.0642 22.5281 65.2159C22.5281 64.3676 21.8404 63.6799 20.9921 63.6799C20.1437 63.6799 19.4561 64.3676 19.4561 65.2159C19.4561 66.0642 20.1437 66.7519 20.9921 66.7519Z"
            fill="white"
          />
          <path
            d="M20.9921 69.824C21.8404 69.824 22.5281 69.1363 22.5281 68.288C22.5281 67.4396 21.8404 66.752 20.9921 66.752C20.1437 66.752 19.4561 67.4396 19.4561 68.288C19.4561 69.1363 20.1437 69.824 20.9921 69.824Z"
            fill="white"
          />
          <path
            d="M20.9921 72.896C21.8404 72.896 22.5281 72.2083 22.5281 71.36C22.5281 70.5117 21.8404 69.824 20.9921 69.824C20.1437 69.824 19.4561 70.5117 19.4561 71.36C19.4561 72.2083 20.1437 72.896 20.9921 72.896Z"
            fill="white"
          />
          <path
            d="M20.9921 75.9679C21.8404 75.9679 22.5281 75.2802 22.5281 74.4319C22.5281 73.5836 21.8404 72.8959 20.9921 72.8959C20.1437 72.8959 19.4561 73.5836 19.4561 74.4319C19.4561 75.2802 20.1437 75.9679 20.9921 75.9679Z"
            fill="white"
          />
          <path
            d="M24.0643 39.104C24.9126 39.104 25.6003 38.4163 25.6003 37.568C25.6003 36.7197 24.9126 36.032 24.0643 36.032C23.216 36.032 22.5283 36.7197 22.5283 37.568C22.5283 38.4163 23.216 39.104 24.0643 39.104Z"
            fill="white"
          />
          <path
            d="M24.0643 51.3919C24.9126 51.3919 25.6003 50.7043 25.6003 49.8559C25.6003 49.0076 24.9126 48.3199 24.0643 48.3199C23.216 48.3199 22.5283 49.0076 22.5283 49.8559C22.5283 50.7043 23.216 51.3919 24.0643 51.3919Z"
            fill="white"
          />
          <path
            d="M24.0643 54.464C24.9126 54.464 25.6003 53.7763 25.6003 52.928C25.6003 52.0797 24.9126 51.392 24.0643 51.392C23.216 51.392 22.5283 52.0797 22.5283 52.928C22.5283 53.7763 23.216 54.464 24.0643 54.464Z"
            fill="white"
          />
          <path
            d="M24.0643 57.5359C24.9126 57.5359 25.6003 56.8482 25.6003 55.9999C25.6003 55.1516 24.9126 54.4639 24.0643 54.4639C23.216 54.4639 22.5283 55.1516 22.5283 55.9999C22.5283 56.8482 23.216 57.5359 24.0643 57.5359Z"
            fill="white"
          />
          <path
            d="M24.0643 60.6079C24.9126 60.6079 25.6003 59.9202 25.6003 59.0719C25.6003 58.2236 24.9126 57.5359 24.0643 57.5359C23.216 57.5359 22.5283 58.2236 22.5283 59.0719C22.5283 59.9202 23.216 60.6079 24.0643 60.6079Z"
            fill="white"
          />
          <path
            d="M24.0643 66.7519C24.9126 66.7519 25.6003 66.0642 25.6003 65.2159C25.6003 64.3676 24.9126 63.6799 24.0643 63.6799C23.216 63.6799 22.5283 64.3676 22.5283 65.2159C22.5283 66.0642 23.216 66.7519 24.0643 66.7519Z"
            fill="white"
          />
          <path
            d="M24.0643 72.896C24.9126 72.896 25.6003 72.2083 25.6003 71.36C25.6003 70.5117 24.9126 69.824 24.0643 69.824C23.216 69.824 22.5283 70.5117 22.5283 71.36C22.5283 72.2083 23.216 72.896 24.0643 72.896Z"
            fill="white"
          />
          <path
            d="M24.0643 75.9679C24.9126 75.9679 25.6003 75.2802 25.6003 74.4319C25.6003 73.5836 24.9126 72.8959 24.0643 72.8959C23.216 72.8959 22.5283 73.5836 22.5283 74.4319C22.5283 75.2802 23.216 75.9679 24.0643 75.9679Z"
            fill="white"
          />
          <path
            d="M27.1361 42.1759C27.9844 42.1759 28.6721 41.4882 28.6721 40.6399C28.6721 39.7916 27.9844 39.1039 27.1361 39.1039C26.2878 39.1039 25.6001 39.7916 25.6001 40.6399C25.6001 41.4882 26.2878 42.1759 27.1361 42.1759Z"
            fill="white"
          />
          <path
            d="M27.1361 63.6799C27.9844 63.6799 28.6721 62.9922 28.6721 62.1439C28.6721 61.2956 27.9844 60.6079 27.1361 60.6079C26.2878 60.6079 25.6001 61.2956 25.6001 62.1439C25.6001 62.9922 26.2878 63.6799 27.1361 63.6799Z"
            fill="white"
          />
          <path
            d="M27.1361 72.896C27.9844 72.896 28.6721 72.2083 28.6721 71.36C28.6721 70.5117 27.9844 69.824 27.1361 69.824C26.2878 69.824 25.6001 70.5117 25.6001 71.36C25.6001 72.2083 26.2878 72.896 27.1361 72.896Z"
            fill="white"
          />
          <path
            d="M30.2079 39.104C31.0562 39.104 31.7439 38.4163 31.7439 37.568C31.7439 36.7197 31.0562 36.032 30.2079 36.032C29.3596 36.032 28.6719 36.7197 28.6719 37.568C28.6719 38.4163 29.3596 39.104 30.2079 39.104Z"
            fill="white"
          />
          <path
            d="M30.2079 48.3199C31.0562 48.3199 31.7439 47.6322 31.7439 46.7839C31.7439 45.9356 31.0562 45.2479 30.2079 45.2479C29.3596 45.2479 28.6719 45.9356 28.6719 46.7839C28.6719 47.6322 29.3596 48.3199 30.2079 48.3199Z"
            fill="white"
          />
          <path
            d="M30.2079 54.464C31.0562 54.464 31.7439 53.7763 31.7439 52.928C31.7439 52.0797 31.0562 51.392 30.2079 51.392C29.3596 51.392 28.6719 52.0797 28.6719 52.928C28.6719 53.7763 29.3596 54.464 30.2079 54.464Z"
            fill="white"
          />
          <path
            d="M30.2079 57.5359C31.0562 57.5359 31.7439 56.8482 31.7439 55.9999C31.7439 55.1516 31.0562 54.4639 30.2079 54.4639C29.3596 54.4639 28.6719 55.1516 28.6719 55.9999C28.6719 56.8482 29.3596 57.5359 30.2079 57.5359Z"
            fill="white"
          />
          <path
            d="M30.2079 60.6079C31.0562 60.6079 31.7439 59.9202 31.7439 59.0719C31.7439 58.2236 31.0562 57.5359 30.2079 57.5359C29.3596 57.5359 28.6719 58.2236 28.6719 59.0719C28.6719 59.9202 29.3596 60.6079 30.2079 60.6079Z"
            fill="white"
          />
          <path
            d="M30.2079 63.6799C31.0562 63.6799 31.7439 62.9922 31.7439 62.1439C31.7439 61.2956 31.0562 60.6079 30.2079 60.6079C29.3596 60.6079 28.6719 61.2956 28.6719 62.1439C28.6719 62.9922 29.3596 63.6799 30.2079 63.6799Z"
            fill="white"
          />
          <path
            d="M30.2079 66.7519C31.0562 66.7519 31.7439 66.0642 31.7439 65.2159C31.7439 64.3676 31.0562 63.6799 30.2079 63.6799C29.3596 63.6799 28.6719 64.3676 28.6719 65.2159C28.6719 66.0642 29.3596 66.7519 30.2079 66.7519Z"
            fill="white"
          />
          <path
            d="M30.2079 69.824C31.0562 69.824 31.7439 69.1363 31.7439 68.288C31.7439 67.4396 31.0562 66.752 30.2079 66.752C29.3596 66.752 28.6719 67.4396 28.6719 68.288C28.6719 69.1363 29.3596 69.824 30.2079 69.824Z"
            fill="white"
          />
          <path
            d="M33.2801 39.104C34.1285 39.104 34.8161 38.4163 34.8161 37.568C34.8161 36.7197 34.1285 36.032 33.2801 36.032C32.4318 36.032 31.7441 36.7197 31.7441 37.568C31.7441 38.4163 32.4318 39.104 33.2801 39.104Z"
            fill="white"
          />
          <path
            d="M33.2801 42.1759C34.1285 42.1759 34.8161 41.4882 34.8161 40.6399C34.8161 39.7916 34.1285 39.1039 33.2801 39.1039C32.4318 39.1039 31.7441 39.7916 31.7441 40.6399C31.7441 41.4882 32.4318 42.1759 33.2801 42.1759Z"
            fill="white"
          />
          <path
            d="M33.2801 45.2479C34.1285 45.2479 34.8161 44.5602 34.8161 43.7119C34.8161 42.8636 34.1285 42.1759 33.2801 42.1759C32.4318 42.1759 31.7441 42.8636 31.7441 43.7119C31.7441 44.5602 32.4318 45.2479 33.2801 45.2479Z"
            fill="white"
          />
          <path
            d="M33.2801 48.3199C34.1285 48.3199 34.8161 47.6322 34.8161 46.7839C34.8161 45.9356 34.1285 45.2479 33.2801 45.2479C32.4318 45.2479 31.7441 45.9356 31.7441 46.7839C31.7441 47.6322 32.4318 48.3199 33.2801 48.3199Z"
            fill="white"
          />
          <path
            d="M33.2801 51.3919C34.1285 51.3919 34.8161 50.7043 34.8161 49.8559C34.8161 49.0076 34.1285 48.3199 33.2801 48.3199C32.4318 48.3199 31.7441 49.0076 31.7441 49.8559C31.7441 50.7043 32.4318 51.3919 33.2801 51.3919Z"
            fill="white"
          />
          <path
            d="M33.2801 57.5359C34.1285 57.5359 34.8161 56.8482 34.8161 55.9999C34.8161 55.1516 34.1285 54.4639 33.2801 54.4639C32.4318 54.4639 31.7441 55.1516 31.7441 55.9999C31.7441 56.8482 32.4318 57.5359 33.2801 57.5359Z"
            fill="white"
          />
          <path
            d="M33.2801 60.6079C34.1285 60.6079 34.8161 59.9202 34.8161 59.0719C34.8161 58.2236 34.1285 57.5359 33.2801 57.5359C32.4318 57.5359 31.7441 58.2236 31.7441 59.0719C31.7441 59.9202 32.4318 60.6079 33.2801 60.6079Z"
            fill="white"
          />
          <path
            d="M33.2801 69.824C34.1285 69.824 34.8161 69.1363 34.8161 68.288C34.8161 67.4396 34.1285 66.752 33.2801 66.752C32.4318 66.752 31.7441 67.4396 31.7441 68.288C31.7441 69.1363 32.4318 69.824 33.2801 69.824Z"
            fill="white"
          />
          <path
            d="M33.2801 72.896C34.1285 72.896 34.8161 72.2083 34.8161 71.36C34.8161 70.5117 34.1285 69.824 33.2801 69.824C32.4318 69.824 31.7441 70.5117 31.7441 71.36C31.7441 72.2083 32.4318 72.896 33.2801 72.896Z"
            fill="white"
          />
          <path
            d="M36.3519 42.1759C37.2002 42.1759 37.8879 41.4882 37.8879 40.6399C37.8879 39.7916 37.2002 39.1039 36.3519 39.1039C35.5036 39.1039 34.8159 39.7916 34.8159 40.6399C34.8159 41.4882 35.5036 42.1759 36.3519 42.1759Z"
            fill="white"
          />
          <path
            d="M36.3519 45.2479C37.2002 45.2479 37.8879 44.5602 37.8879 43.7119C37.8879 42.8636 37.2002 42.1759 36.3519 42.1759C35.5036 42.1759 34.8159 42.8636 34.8159 43.7119C34.8159 44.5602 35.5036 45.2479 36.3519 45.2479Z"
            fill="white"
          />
          <path
            d="M36.3519 48.3199C37.2002 48.3199 37.8879 47.6322 37.8879 46.7839C37.8879 45.9356 37.2002 45.2479 36.3519 45.2479C35.5036 45.2479 34.8159 45.9356 34.8159 46.7839C34.8159 47.6322 35.5036 48.3199 36.3519 48.3199Z"
            fill="white"
          />
          <path
            d="M36.3519 54.464C37.2002 54.464 37.8879 53.7763 37.8879 52.928C37.8879 52.0797 37.2002 51.392 36.3519 51.392C35.5036 51.392 34.8159 52.0797 34.8159 52.928C34.8159 53.7763 35.5036 54.464 36.3519 54.464Z"
            fill="white"
          />
          <path
            d="M36.3519 75.9679C37.2002 75.9679 37.8879 75.2802 37.8879 74.4319C37.8879 73.5836 37.2002 72.8959 36.3519 72.8959C35.5036 72.8959 34.8159 73.5836 34.8159 74.4319C34.8159 75.2802 35.5036 75.9679 36.3519 75.9679Z"
            fill="white"
          />
          <path
            d="M39.4242 39.104C40.2725 39.104 40.9602 38.4163 40.9602 37.568C40.9602 36.7197 40.2725 36.032 39.4242 36.032C38.5759 36.032 37.8882 36.7197 37.8882 37.568C37.8882 38.4163 38.5759 39.104 39.4242 39.104Z"
            fill="white"
          />
          <path
            d="M39.4242 45.2479C40.2725 45.2479 40.9602 44.5602 40.9602 43.7119C40.9602 42.8636 40.2725 42.1759 39.4242 42.1759C38.5759 42.1759 37.8882 42.8636 37.8882 43.7119C37.8882 44.5602 38.5759 45.2479 39.4242 45.2479Z"
            fill="white"
          />
          <path
            d="M39.4242 51.3919C40.2725 51.3919 40.9602 50.7043 40.9602 49.8559C40.9602 49.0076 40.2725 48.3199 39.4242 48.3199C38.5759 48.3199 37.8882 49.0076 37.8882 49.8559C37.8882 50.7043 38.5759 51.3919 39.4242 51.3919Z"
            fill="white"
          />
          <path
            d="M39.4242 57.5359C40.2725 57.5359 40.9602 56.8482 40.9602 55.9999C40.9602 55.1516 40.2725 54.4639 39.4242 54.4639C38.5759 54.4639 37.8882 55.1516 37.8882 55.9999C37.8882 56.8482 38.5759 57.5359 39.4242 57.5359Z"
            fill="white"
          />
          <path
            d="M39.4242 63.6799C40.2725 63.6799 40.9602 62.9922 40.9602 62.1439C40.9602 61.2956 40.2725 60.6079 39.4242 60.6079C38.5759 60.6079 37.8882 61.2956 37.8882 62.1439C37.8882 62.9922 38.5759 63.6799 39.4242 63.6799Z"
            fill="white"
          />
          <path
            d="M39.4242 69.824C40.2725 69.824 40.9602 69.1363 40.9602 68.288C40.9602 67.4396 40.2725 66.752 39.4242 66.752C38.5759 66.752 37.8882 67.4396 37.8882 68.288C37.8882 69.1363 38.5759 69.824 39.4242 69.824Z"
            fill="white"
          />
          <path
            d="M39.4242 75.9679C40.2725 75.9679 40.9602 75.2802 40.9602 74.4319C40.9602 73.5836 40.2725 72.8959 39.4242 72.8959C38.5759 72.8959 37.8882 73.5836 37.8882 74.4319C37.8882 75.2802 38.5759 75.9679 39.4242 75.9679Z"
            fill="white"
          />
          <path
            d="M42.496 39.104C43.3443 39.104 44.032 38.4163 44.032 37.568C44.032 36.7197 43.3443 36.032 42.496 36.032C41.6477 36.032 40.96 36.7197 40.96 37.568C40.96 38.4163 41.6477 39.104 42.496 39.104Z"
            fill="white"
          />
          <path
            d="M42.496 42.1759C43.3443 42.1759 44.032 41.4882 44.032 40.6399C44.032 39.7916 43.3443 39.1039 42.496 39.1039C41.6477 39.1039 40.96 39.7916 40.96 40.6399C40.96 41.4882 41.6477 42.1759 42.496 42.1759Z"
            fill="white"
          />
          <path
            d="M42.496 54.464C43.3443 54.464 44.032 53.7763 44.032 52.928C44.032 52.0797 43.3443 51.392 42.496 51.392C41.6477 51.392 40.96 52.0797 40.96 52.928C40.96 53.7763 41.6477 54.464 42.496 54.464Z"
            fill="white"
          />
          <path
            d="M42.496 57.5359C43.3443 57.5359 44.032 56.8482 44.032 55.9999C44.032 55.1516 43.3443 54.4639 42.496 54.4639C41.6477 54.4639 40.96 55.1516 40.96 55.9999C40.96 56.8482 41.6477 57.5359 42.496 57.5359Z"
            fill="white"
          />
          <path
            d="M42.496 66.7519C43.3443 66.7519 44.032 66.0642 44.032 65.2159C44.032 64.3676 43.3443 63.6799 42.496 63.6799C41.6477 63.6799 40.96 64.3676 40.96 65.2159C40.96 66.0642 41.6477 66.7519 42.496 66.7519Z"
            fill="white"
          />
          <path
            d="M42.496 69.824C43.3443 69.824 44.032 69.1363 44.032 68.288C44.032 67.4396 43.3443 66.752 42.496 66.752C41.6477 66.752 40.96 67.4396 40.96 68.288C40.96 69.1363 41.6477 69.824 42.496 69.824Z"
            fill="white"
          />
          <path
            d="M45.5682 17.6C46.4165 17.6 47.1042 16.9123 47.1042 16.064C47.1042 15.2156 46.4165 14.528 45.5682 14.528C44.7199 14.528 44.0322 15.2156 44.0322 16.064C44.0322 16.9123 44.7199 17.6 45.5682 17.6Z"
            fill="white"
          />
          <path
            d="M45.5682 23.7439C46.4165 23.7439 47.1042 23.0562 47.1042 22.2079C47.1042 21.3596 46.4165 20.6719 45.5682 20.6719C44.7199 20.6719 44.0322 21.3596 44.0322 22.2079C44.0322 23.0562 44.7199 23.7439 45.5682 23.7439Z"
            fill="white"
          />
          <path
            d="M45.5682 26.8159C46.4165 26.8159 47.1042 26.1282 47.1042 25.2799C47.1042 24.4316 46.4165 23.7439 45.5682 23.7439C44.7199 23.7439 44.0322 24.4316 44.0322 25.2799C44.0322 26.1282 44.7199 26.8159 45.5682 26.8159Z"
            fill="white"
          />
          <path
            d="M45.5682 29.8879C46.4165 29.8879 47.1042 29.2002 47.1042 28.3519C47.1042 27.5036 46.4165 26.8159 45.5682 26.8159C44.7199 26.8159 44.0322 27.5036 44.0322 28.3519C44.0322 29.2002 44.7199 29.8879 45.5682 29.8879Z"
            fill="white"
          />
          <path
            d="M45.5682 32.9599C46.4165 32.9599 47.1042 32.2722 47.1042 31.4239C47.1042 30.5756 46.4165 29.8879 45.5682 29.8879C44.7199 29.8879 44.0322 30.5756 44.0322 31.4239C44.0322 32.2722 44.7199 32.9599 45.5682 32.9599Z"
            fill="white"
          />
          <path
            d="M45.5682 39.104C46.4165 39.104 47.1042 38.4163 47.1042 37.568C47.1042 36.7197 46.4165 36.032 45.5682 36.032C44.7199 36.032 44.0322 36.7197 44.0322 37.568C44.0322 38.4163 44.7199 39.104 45.5682 39.104Z"
            fill="white"
          />
          <path
            d="M45.5682 54.464C46.4165 54.464 47.1042 53.7763 47.1042 52.928C47.1042 52.0797 46.4165 51.392 45.5682 51.392C44.7199 51.392 44.0322 52.0797 44.0322 52.928C44.0322 53.7763 44.7199 54.464 45.5682 54.464Z"
            fill="white"
          />
          <path
            d="M45.5682 57.5359C46.4165 57.5359 47.1042 56.8482 47.1042 55.9999C47.1042 55.1516 46.4165 54.4639 45.5682 54.4639C44.7199 54.4639 44.0322 55.1516 44.0322 55.9999C44.0322 56.8482 44.7199 57.5359 45.5682 57.5359Z"
            fill="white"
          />
          <path
            d="M45.5682 72.896C46.4165 72.896 47.1042 72.2083 47.1042 71.36C47.1042 70.5117 46.4165 69.824 45.5682 69.824C44.7199 69.824 44.0322 70.5117 44.0322 71.36C44.0322 72.2083 44.7199 72.896 45.5682 72.896Z"
            fill="white"
          />
          <path
            d="M45.5682 75.9679C46.4165 75.9679 47.1042 75.2802 47.1042 74.4319C47.1042 73.5836 46.4165 72.8959 45.5682 72.8959C44.7199 72.8959 44.0322 73.5836 44.0322 74.4319C44.0322 75.2802 44.7199 75.9679 45.5682 75.9679Z"
            fill="white"
          />
          <path
            d="M45.5682 79.0399C46.4165 79.0399 47.1042 78.3522 47.1042 77.5039C47.1042 76.6556 46.4165 75.9679 45.5682 75.9679C44.7199 75.9679 44.0322 76.6556 44.0322 77.5039C44.0322 78.3522 44.7199 79.0399 45.5682 79.0399Z"
            fill="white"
          />
          <path
            d="M45.5682 82.1119C46.4165 82.1119 47.1042 81.4242 47.1042 80.5759C47.1042 79.7276 46.4165 79.0399 45.5682 79.0399C44.7199 79.0399 44.0322 79.7276 44.0322 80.5759C44.0322 81.4242 44.7199 82.1119 45.5682 82.1119Z"
            fill="white"
          />
          <path
            d="M45.5682 88.256C46.4165 88.256 47.1042 87.5683 47.1042 86.72C47.1042 85.8717 46.4165 85.184 45.5682 85.184C44.7199 85.184 44.0322 85.8717 44.0322 86.72C44.0322 87.5683 44.7199 88.256 45.5682 88.256Z"
            fill="white"
          />
          <path
            d="M45.5682 91.328C46.4165 91.328 47.1042 90.6403 47.1042 89.792C47.1042 88.9437 46.4165 88.256 45.5682 88.256C44.7199 88.256 44.0322 88.9437 44.0322 89.792C44.0322 90.6403 44.7199 91.328 45.5682 91.328Z"
            fill="white"
          />
          <path
            d="M45.5682 97.4719C46.4165 97.4719 47.1042 96.7842 47.1042 95.9359C47.1042 95.0876 46.4165 94.3999 45.5682 94.3999C44.7199 94.3999 44.0322 95.0876 44.0322 95.9359C44.0322 96.7842 44.7199 97.4719 45.5682 97.4719Z"
            fill="white"
          />
          <path
            d="M48.64 14.5279C49.4883 14.5279 50.176 13.8402 50.176 12.9919C50.176 12.1436 49.4883 11.4559 48.64 11.4559C47.7917 11.4559 47.104 12.1436 47.104 12.9919C47.104 13.8402 47.7917 14.5279 48.64 14.5279Z"
            fill="white"
          />
          <path
            d="M48.64 26.8159C49.4883 26.8159 50.176 26.1282 50.176 25.2799C50.176 24.4316 49.4883 23.7439 48.64 23.7439C47.7917 23.7439 47.104 24.4316 47.104 25.2799C47.104 26.1282 47.7917 26.8159 48.64 26.8159Z"
            fill="white"
          />
          <path
            d="M48.64 29.8879C49.4883 29.8879 50.176 29.2002 50.176 28.3519C50.176 27.5036 49.4883 26.8159 48.64 26.8159C47.7917 26.8159 47.104 27.5036 47.104 28.3519C47.104 29.2002 47.7917 29.8879 48.64 29.8879Z"
            fill="white"
          />
          <path
            d="M48.64 39.104C49.4883 39.104 50.176 38.4163 50.176 37.568C50.176 36.7197 49.4883 36.032 48.64 36.032C47.7917 36.032 47.104 36.7197 47.104 37.568C47.104 38.4163 47.7917 39.104 48.64 39.104Z"
            fill="white"
          />
          <path
            d="M48.64 45.2479C49.4883 45.2479 50.176 44.5602 50.176 43.7119C50.176 42.8636 49.4883 42.1759 48.64 42.1759C47.7917 42.1759 47.104 42.8636 47.104 43.7119C47.104 44.5602 47.7917 45.2479 48.64 45.2479Z"
            fill="white"
          />
          <path
            d="M48.64 51.3919C49.4883 51.3919 50.176 50.7043 50.176 49.8559C50.176 49.0076 49.4883 48.3199 48.64 48.3199C47.7917 48.3199 47.104 49.0076 47.104 49.8559C47.104 50.7043 47.7917 51.3919 48.64 51.3919Z"
            fill="white"
          />
          <path
            d="M48.64 54.464C49.4883 54.464 50.176 53.7763 50.176 52.928C50.176 52.0797 49.4883 51.392 48.64 51.392C47.7917 51.392 47.104 52.0797 47.104 52.928C47.104 53.7763 47.7917 54.464 48.64 54.464Z"
            fill="white"
          />
          <path
            d="M48.64 57.5359C49.4883 57.5359 50.176 56.8482 50.176 55.9999C50.176 55.1516 49.4883 54.4639 48.64 54.4639C47.7917 54.4639 47.104 55.1516 47.104 55.9999C47.104 56.8482 47.7917 57.5359 48.64 57.5359Z"
            fill="white"
          />
          <path
            d="M48.64 60.6079C49.4883 60.6079 50.176 59.9202 50.176 59.0719C50.176 58.2236 49.4883 57.5359 48.64 57.5359C47.7917 57.5359 47.104 58.2236 47.104 59.0719C47.104 59.9202 47.7917 60.6079 48.64 60.6079Z"
            fill="white"
          />
          <path
            d="M48.64 66.7519C49.4883 66.7519 50.176 66.0642 50.176 65.2159C50.176 64.3676 49.4883 63.6799 48.64 63.6799C47.7917 63.6799 47.104 64.3676 47.104 65.2159C47.104 66.0642 47.7917 66.7519 48.64 66.7519Z"
            fill="white"
          />
          <path
            d="M48.64 69.824C49.4883 69.824 50.176 69.1363 50.176 68.288C50.176 67.4396 49.4883 66.752 48.64 66.752C47.7917 66.752 47.104 67.4396 47.104 68.288C47.104 69.1363 47.7917 69.824 48.64 69.824Z"
            fill="white"
          />
          <path
            d="M48.64 72.896C49.4883 72.896 50.176 72.2083 50.176 71.36C50.176 70.5117 49.4883 69.824 48.64 69.824C47.7917 69.824 47.104 70.5117 47.104 71.36C47.104 72.2083 47.7917 72.896 48.64 72.896Z"
            fill="white"
          />
          <path
            d="M48.64 85.1839C49.4883 85.1839 50.176 84.4962 50.176 83.6479C50.176 82.7996 49.4883 82.1119 48.64 82.1119C47.7917 82.1119 47.104 82.7996 47.104 83.6479C47.104 84.4962 47.7917 85.1839 48.64 85.1839Z"
            fill="white"
          />
          <path
            d="M48.64 88.256C49.4883 88.256 50.176 87.5683 50.176 86.72C50.176 85.8717 49.4883 85.184 48.64 85.184C47.7917 85.184 47.104 85.8717 47.104 86.72C47.104 87.5683 47.7917 88.256 48.64 88.256Z"
            fill="white"
          />
          <path
            d="M48.64 91.328C49.4883 91.328 50.176 90.6403 50.176 89.792C50.176 88.9437 49.4883 88.256 48.64 88.256C47.7917 88.256 47.104 88.9437 47.104 89.792C47.104 90.6403 47.7917 91.328 48.64 91.328Z"
            fill="white"
          />
          <path
            d="M48.64 94.3999C49.4883 94.3999 50.176 93.7122 50.176 92.8639C50.176 92.0156 49.4883 91.3279 48.64 91.3279C47.7917 91.3279 47.104 92.0156 47.104 92.8639C47.104 93.7122 47.7917 94.3999 48.64 94.3999Z"
            fill="white"
          />
          <path
            d="M48.64 97.4719C49.4883 97.4719 50.176 96.7842 50.176 95.9359C50.176 95.0876 49.4883 94.3999 48.64 94.3999C47.7917 94.3999 47.104 95.0876 47.104 95.9359C47.104 96.7842 47.7917 97.4719 48.64 97.4719Z"
            fill="white"
          />
          <path
            d="M51.7123 17.6C52.5606 17.6 53.2483 16.9123 53.2483 16.064C53.2483 15.2156 52.5606 14.528 51.7123 14.528C50.864 14.528 50.1763 15.2156 50.1763 16.064C50.1763 16.9123 50.864 17.6 51.7123 17.6Z"
            fill="white"
          />
          <path
            d="M51.7123 20.672C52.5606 20.672 53.2483 19.9843 53.2483 19.136C53.2483 18.2877 52.5606 17.6 51.7123 17.6C50.864 17.6 50.1763 18.2877 50.1763 19.136C50.1763 19.9843 50.864 20.672 51.7123 20.672Z"
            fill="white"
          />
          <path
            d="M51.7123 26.8159C52.5606 26.8159 53.2483 26.1282 53.2483 25.2799C53.2483 24.4316 52.5606 23.7439 51.7123 23.7439C50.864 23.7439 50.1763 24.4316 50.1763 25.2799C50.1763 26.1282 50.864 26.8159 51.7123 26.8159Z"
            fill="white"
          />
          <path
            d="M51.7123 32.9599C52.5606 32.9599 53.2483 32.2722 53.2483 31.4239C53.2483 30.5756 52.5606 29.8879 51.7123 29.8879C50.864 29.8879 50.1763 30.5756 50.1763 31.4239C50.1763 32.2722 50.864 32.9599 51.7123 32.9599Z"
            fill="white"
          />
          <path
            d="M51.7123 39.104C52.5606 39.104 53.2483 38.4163 53.2483 37.568C53.2483 36.7197 52.5606 36.032 51.7123 36.032C50.864 36.032 50.1763 36.7197 50.1763 37.568C50.1763 38.4163 50.864 39.104 51.7123 39.104Z"
            fill="white"
          />
          <path
            d="M51.7123 48.3199C52.5606 48.3199 53.2483 47.6322 53.2483 46.7839C53.2483 45.9356 52.5606 45.2479 51.7123 45.2479C50.864 45.2479 50.1763 45.9356 50.1763 46.7839C50.1763 47.6322 50.864 48.3199 51.7123 48.3199Z"
            fill="white"
          />
          <path
            d="M51.7123 51.3919C52.5606 51.3919 53.2483 50.7043 53.2483 49.8559C53.2483 49.0076 52.5606 48.3199 51.7123 48.3199C50.864 48.3199 50.1763 49.0076 50.1763 49.8559C50.1763 50.7043 50.864 51.3919 51.7123 51.3919Z"
            fill="white"
          />
          <path
            d="M51.7123 54.464C52.5606 54.464 53.2483 53.7763 53.2483 52.928C53.2483 52.0797 52.5606 51.392 51.7123 51.392C50.864 51.392 50.1763 52.0797 50.1763 52.928C50.1763 53.7763 50.864 54.464 51.7123 54.464Z"
            fill="white"
          />
          <path
            d="M51.7123 63.6799C52.5606 63.6799 53.2483 62.9922 53.2483 62.1439C53.2483 61.2956 52.5606 60.6079 51.7123 60.6079C50.864 60.6079 50.1763 61.2956 50.1763 62.1439C50.1763 62.9922 50.864 63.6799 51.7123 63.6799Z"
            fill="white"
          />
          <path
            d="M51.7123 75.9679C52.5606 75.9679 53.2483 75.2802 53.2483 74.4319C53.2483 73.5836 52.5606 72.8959 51.7123 72.8959C50.864 72.8959 50.1763 73.5836 50.1763 74.4319C50.1763 75.2802 50.864 75.9679 51.7123 75.9679Z"
            fill="white"
          />
          <path
            d="M51.7123 79.0399C52.5606 79.0399 53.2483 78.3522 53.2483 77.5039C53.2483 76.6556 52.5606 75.9679 51.7123 75.9679C50.864 75.9679 50.1763 76.6556 50.1763 77.5039C50.1763 78.3522 50.864 79.0399 51.7123 79.0399Z"
            fill="white"
          />
          <path
            d="M51.7123 82.1119C52.5606 82.1119 53.2483 81.4242 53.2483 80.5759C53.2483 79.7276 52.5606 79.0399 51.7123 79.0399C50.864 79.0399 50.1763 79.7276 50.1763 80.5759C50.1763 81.4242 50.864 82.1119 51.7123 82.1119Z"
            fill="white"
          />
          <path
            d="M51.7123 91.328C52.5606 91.328 53.2483 90.6403 53.2483 89.792C53.2483 88.9437 52.5606 88.256 51.7123 88.256C50.864 88.256 50.1763 88.9437 50.1763 89.792C50.1763 90.6403 50.864 91.328 51.7123 91.328Z"
            fill="white"
          />
          <path
            d="M51.7123 94.3999C52.5606 94.3999 53.2483 93.7122 53.2483 92.8639C53.2483 92.0156 52.5606 91.3279 51.7123 91.3279C50.864 91.3279 50.1763 92.0156 50.1763 92.8639C50.1763 93.7122 50.864 94.3999 51.7123 94.3999Z"
            fill="white"
          />
          <path
            d="M54.784 23.7439C55.6324 23.7439 56.32 23.0562 56.32 22.2079C56.32 21.3596 55.6324 20.6719 54.784 20.6719C53.9357 20.6719 53.248 21.3596 53.248 22.2079C53.248 23.0562 53.9357 23.7439 54.784 23.7439Z"
            fill="white"
          />
          <path
            d="M54.784 26.8159C55.6324 26.8159 56.32 26.1282 56.32 25.2799C56.32 24.4316 55.6324 23.7439 54.784 23.7439C53.9357 23.7439 53.248 24.4316 53.248 25.2799C53.248 26.1282 53.9357 26.8159 54.784 26.8159Z"
            fill="white"
          />
          <path
            d="M54.784 42.1759C55.6324 42.1759 56.32 41.4882 56.32 40.6399C56.32 39.7916 55.6324 39.1039 54.784 39.1039C53.9357 39.1039 53.248 39.7916 53.248 40.6399C53.248 41.4882 53.9357 42.1759 54.784 42.1759Z"
            fill="white"
          />
          <path
            d="M54.784 45.2479C55.6324 45.2479 56.32 44.5602 56.32 43.7119C56.32 42.8636 55.6324 42.1759 54.784 42.1759C53.9357 42.1759 53.248 42.8636 53.248 43.7119C53.248 44.5602 53.9357 45.2479 54.784 45.2479Z"
            fill="white"
          />
          <path
            d="M54.784 57.5359C55.6324 57.5359 56.32 56.8482 56.32 55.9999C56.32 55.1516 55.6324 54.4639 54.784 54.4639C53.9357 54.4639 53.248 55.1516 53.248 55.9999C53.248 56.8482 53.9357 57.5359 54.784 57.5359Z"
            fill="white"
          />
          <path
            d="M54.784 60.6079C55.6324 60.6079 56.32 59.9202 56.32 59.0719C56.32 58.2236 55.6324 57.5359 54.784 57.5359C53.9357 57.5359 53.248 58.2236 53.248 59.0719C53.248 59.9202 53.9357 60.6079 54.784 60.6079Z"
            fill="white"
          />
          <path
            d="M54.784 66.7519C55.6324 66.7519 56.32 66.0642 56.32 65.2159C56.32 64.3676 55.6324 63.6799 54.784 63.6799C53.9357 63.6799 53.248 64.3676 53.248 65.2159C53.248 66.0642 53.9357 66.7519 54.784 66.7519Z"
            fill="white"
          />
          <path
            d="M54.784 69.824C55.6324 69.824 56.32 69.1363 56.32 68.288C56.32 67.4396 55.6324 66.752 54.784 66.752C53.9357 66.752 53.248 67.4396 53.248 68.288C53.248 69.1363 53.9357 69.824 54.784 69.824Z"
            fill="white"
          />
          <path
            d="M54.784 91.328C55.6324 91.328 56.32 90.6403 56.32 89.792C56.32 88.9437 55.6324 88.256 54.784 88.256C53.9357 88.256 53.248 88.9437 53.248 89.792C53.248 90.6403 53.9357 91.328 54.784 91.328Z"
            fill="white"
          />
          <path
            d="M54.784 100.544C55.6324 100.544 56.32 99.8562 56.32 99.0079C56.32 98.1596 55.6324 97.4719 54.784 97.4719C53.9357 97.4719 53.248 98.1596 53.248 99.0079C53.248 99.8562 53.9357 100.544 54.784 100.544Z"
            fill="white"
          />
          <path
            d="M57.8563 17.6C58.7046 17.6 59.3923 16.9123 59.3923 16.064C59.3923 15.2156 58.7046 14.528 57.8563 14.528C57.008 14.528 56.3203 15.2156 56.3203 16.064C56.3203 16.9123 57.008 17.6 57.8563 17.6Z"
            fill="white"
          />
          <path
            d="M57.8563 20.672C58.7046 20.672 59.3923 19.9843 59.3923 19.136C59.3923 18.2877 58.7046 17.6 57.8563 17.6C57.008 17.6 56.3203 18.2877 56.3203 19.136C56.3203 19.9843 57.008 20.672 57.8563 20.672Z"
            fill="white"
          />
          <path
            d="M57.8563 29.8879C58.7046 29.8879 59.3923 29.2002 59.3923 28.3519C59.3923 27.5036 58.7046 26.8159 57.8563 26.8159C57.008 26.8159 56.3203 27.5036 56.3203 28.3519C56.3203 29.2002 57.008 29.8879 57.8563 29.8879Z"
            fill="white"
          />
          <path
            d="M57.8563 32.9599C58.7046 32.9599 59.3923 32.2722 59.3923 31.4239C59.3923 30.5756 58.7046 29.8879 57.8563 29.8879C57.008 29.8879 56.3203 30.5756 56.3203 31.4239C56.3203 32.2722 57.008 32.9599 57.8563 32.9599Z"
            fill="white"
          />
          <path
            d="M57.8563 36.032C58.7046 36.032 59.3923 35.3443 59.3923 34.496C59.3923 33.6477 58.7046 32.96 57.8563 32.96C57.008 32.96 56.3203 33.6477 56.3203 34.496C56.3203 35.3443 57.008 36.032 57.8563 36.032Z"
            fill="white"
          />
          <path
            d="M57.8563 48.3199C58.7046 48.3199 59.3923 47.6322 59.3923 46.7839C59.3923 45.9356 58.7046 45.2479 57.8563 45.2479C57.008 45.2479 56.3203 45.9356 56.3203 46.7839C56.3203 47.6322 57.008 48.3199 57.8563 48.3199Z"
            fill="white"
          />
          <path
            d="M57.8563 63.6799C58.7046 63.6799 59.3923 62.9922 59.3923 62.1439C59.3923 61.2956 58.7046 60.6079 57.8563 60.6079C57.008 60.6079 56.3203 61.2956 56.3203 62.1439C56.3203 62.9922 57.008 63.6799 57.8563 63.6799Z"
            fill="white"
          />
          <path
            d="M57.8563 72.896C58.7046 72.896 59.3923 72.2083 59.3923 71.36C59.3923 70.5117 58.7046 69.824 57.8563 69.824C57.008 69.824 56.3203 70.5117 56.3203 71.36C56.3203 72.2083 57.008 72.896 57.8563 72.896Z"
            fill="white"
          />
          <path
            d="M57.8563 79.0399C58.7046 79.0399 59.3923 78.3522 59.3923 77.5039C59.3923 76.6556 58.7046 75.9679 57.8563 75.9679C57.008 75.9679 56.3203 76.6556 56.3203 77.5039C56.3203 78.3522 57.008 79.0399 57.8563 79.0399Z"
            fill="white"
          />
          <path
            d="M57.8563 82.1119C58.7046 82.1119 59.3923 81.4242 59.3923 80.5759C59.3923 79.7276 58.7046 79.0399 57.8563 79.0399C57.008 79.0399 56.3203 79.7276 56.3203 80.5759C56.3203 81.4242 57.008 82.1119 57.8563 82.1119Z"
            fill="white"
          />
          <path
            d="M57.8563 91.328C58.7046 91.328 59.3923 90.6403 59.3923 89.792C59.3923 88.9437 58.7046 88.256 57.8563 88.256C57.008 88.256 56.3203 88.9437 56.3203 89.792C56.3203 90.6403 57.008 91.328 57.8563 91.328Z"
            fill="white"
          />
          <path
            d="M57.8563 97.4719C58.7046 97.4719 59.3923 96.7842 59.3923 95.9359C59.3923 95.0876 58.7046 94.3999 57.8563 94.3999C57.008 94.3999 56.3203 95.0876 56.3203 95.9359C56.3203 96.7842 57.008 97.4719 57.8563 97.4719Z"
            fill="white"
          />
          <path
            d="M60.9281 14.5279C61.7764 14.5279 62.4641 13.8402 62.4641 12.9919C62.4641 12.1436 61.7764 11.4559 60.9281 11.4559C60.0798 11.4559 59.3921 12.1436 59.3921 12.9919C59.3921 13.8402 60.0798 14.5279 60.9281 14.5279Z"
            fill="white"
          />
          <path
            d="M60.9281 20.672C61.7764 20.672 62.4641 19.9843 62.4641 19.136C62.4641 18.2877 61.7764 17.6 60.9281 17.6C60.0798 17.6 59.3921 18.2877 59.3921 19.136C59.3921 19.9843 60.0798 20.672 60.9281 20.672Z"
            fill="white"
          />
          <path
            d="M60.9281 29.8879C61.7764 29.8879 62.4641 29.2002 62.4641 28.3519C62.4641 27.5036 61.7764 26.8159 60.9281 26.8159C60.0798 26.8159 59.3921 27.5036 59.3921 28.3519C59.3921 29.2002 60.0798 29.8879 60.9281 29.8879Z"
            fill="white"
          />
          <path
            d="M60.9281 42.1759C61.7764 42.1759 62.4641 41.4882 62.4641 40.6399C62.4641 39.7916 61.7764 39.1039 60.9281 39.1039C60.0798 39.1039 59.3921 39.7916 59.3921 40.6399C59.3921 41.4882 60.0798 42.1759 60.9281 42.1759Z"
            fill="white"
          />
          <path
            d="M60.9281 45.2479C61.7764 45.2479 62.4641 44.5602 62.4641 43.7119C62.4641 42.8636 61.7764 42.1759 60.9281 42.1759C60.0798 42.1759 59.3921 42.8636 59.3921 43.7119C59.3921 44.5602 60.0798 45.2479 60.9281 45.2479Z"
            fill="white"
          />
          <path
            d="M60.9281 48.3199C61.7764 48.3199 62.4641 47.6322 62.4641 46.7839C62.4641 45.9356 61.7764 45.2479 60.9281 45.2479C60.0798 45.2479 59.3921 45.9356 59.3921 46.7839C59.3921 47.6322 60.0798 48.3199 60.9281 48.3199Z"
            fill="white"
          />
          <path
            d="M60.9281 63.6799C61.7764 63.6799 62.4641 62.9922 62.4641 62.1439C62.4641 61.2956 61.7764 60.6079 60.9281 60.6079C60.0798 60.6079 59.3921 61.2956 59.3921 62.1439C59.3921 62.9922 60.0798 63.6799 60.9281 63.6799Z"
            fill="white"
          />
          <path
            d="M60.9281 69.824C61.7764 69.824 62.4641 69.1363 62.4641 68.288C62.4641 67.4396 61.7764 66.752 60.9281 66.752C60.0798 66.752 59.3921 67.4396 59.3921 68.288C59.3921 69.1363 60.0798 69.824 60.9281 69.824Z"
            fill="white"
          />
          <path
            d="M60.9281 82.1119C61.7764 82.1119 62.4641 81.4242 62.4641 80.5759C62.4641 79.7276 61.7764 79.0399 60.9281 79.0399C60.0798 79.0399 59.3921 79.7276 59.3921 80.5759C59.3921 81.4242 60.0798 82.1119 60.9281 82.1119Z"
            fill="white"
          />
          <path
            d="M60.9281 88.256C61.7764 88.256 62.4641 87.5683 62.4641 86.72C62.4641 85.8717 61.7764 85.184 60.9281 85.184C60.0798 85.184 59.3921 85.8717 59.3921 86.72C59.3921 87.5683 60.0798 88.256 60.9281 88.256Z"
            fill="white"
          />
          <path
            d="M60.9281 94.3999C61.7764 94.3999 62.4641 93.7122 62.4641 92.8639C62.4641 92.0156 61.7764 91.3279 60.9281 91.3279C60.0798 91.3279 59.3921 92.0156 59.3921 92.8639C59.3921 93.7122 60.0798 94.3999 60.9281 94.3999Z"
            fill="white"
          />
          <path
            d="M60.9281 97.4719C61.7764 97.4719 62.4641 96.7842 62.4641 95.9359C62.4641 95.0876 61.7764 94.3999 60.9281 94.3999C60.0798 94.3999 59.3921 95.0876 59.3921 95.9359C59.3921 96.7842 60.0798 97.4719 60.9281 97.4719Z"
            fill="white"
          />
          <path
            d="M60.9281 100.544C61.7764 100.544 62.4641 99.8562 62.4641 99.0079C62.4641 98.1596 61.7764 97.4719 60.9281 97.4719C60.0798 97.4719 59.3921 98.1596 59.3921 99.0079C59.3921 99.8562 60.0798 100.544 60.9281 100.544Z"
            fill="white"
          />
          <path
            d="M63.9999 14.5279C64.8482 14.5279 65.5359 13.8402 65.5359 12.9919C65.5359 12.1436 64.8482 11.4559 63.9999 11.4559C63.1516 11.4559 62.4639 12.1436 62.4639 12.9919C62.4639 13.8402 63.1516 14.5279 63.9999 14.5279Z"
            fill="white"
          />
          <path
            d="M63.9999 20.672C64.8482 20.672 65.5359 19.9843 65.5359 19.136C65.5359 18.2877 64.8482 17.6 63.9999 17.6C63.1516 17.6 62.4639 18.2877 62.4639 19.136C62.4639 19.9843 63.1516 20.672 63.9999 20.672Z"
            fill="white"
          />
          <path
            d="M63.9999 23.7439C64.8482 23.7439 65.5359 23.0562 65.5359 22.2079C65.5359 21.3596 64.8482 20.6719 63.9999 20.6719C63.1516 20.6719 62.4639 21.3596 62.4639 22.2079C62.4639 23.0562 63.1516 23.7439 63.9999 23.7439Z"
            fill="white"
          />
          <path
            d="M63.9999 32.9599C64.8482 32.9599 65.5359 32.2722 65.5359 31.4239C65.5359 30.5756 64.8482 29.8879 63.9999 29.8879C63.1516 29.8879 62.4639 30.5756 62.4639 31.4239C62.4639 32.2722 63.1516 32.9599 63.9999 32.9599Z"
            fill="white"
          />
          <path
            d="M63.9999 36.032C64.8482 36.032 65.5359 35.3443 65.5359 34.496C65.5359 33.6477 64.8482 32.96 63.9999 32.96C63.1516 32.96 62.4639 33.6477 62.4639 34.496C62.4639 35.3443 63.1516 36.032 63.9999 36.032Z"
            fill="white"
          />
          <path
            d="M63.9999 39.104C64.8482 39.104 65.5359 38.4163 65.5359 37.568C65.5359 36.7197 64.8482 36.032 63.9999 36.032C63.1516 36.032 62.4639 36.7197 62.4639 37.568C62.4639 38.4163 63.1516 39.104 63.9999 39.104Z"
            fill="white"
          />
          <path
            d="M63.9999 45.2479C64.8482 45.2479 65.5359 44.5602 65.5359 43.7119C65.5359 42.8636 64.8482 42.1759 63.9999 42.1759C63.1516 42.1759 62.4639 42.8636 62.4639 43.7119C62.4639 44.5602 63.1516 45.2479 63.9999 45.2479Z"
            fill="white"
          />
          <path
            d="M63.9999 51.3919C64.8482 51.3919 65.5359 50.7043 65.5359 49.8559C65.5359 49.0076 64.8482 48.3199 63.9999 48.3199C63.1516 48.3199 62.4639 49.0076 62.4639 49.8559C62.4639 50.7043 63.1516 51.3919 63.9999 51.3919Z"
            fill="white"
          />
          <path
            d="M63.9999 54.464C64.8482 54.464 65.5359 53.7763 65.5359 52.928C65.5359 52.0797 64.8482 51.392 63.9999 51.392C63.1516 51.392 62.4639 52.0797 62.4639 52.928C62.4639 53.7763 63.1516 54.464 63.9999 54.464Z"
            fill="white"
          />
          <path
            d="M63.9999 69.824C64.8482 69.824 65.5359 69.1363 65.5359 68.288C65.5359 67.4396 64.8482 66.752 63.9999 66.752C63.1516 66.752 62.4639 67.4396 62.4639 68.288C62.4639 69.1363 63.1516 69.824 63.9999 69.824Z"
            fill="white"
          />
          <path
            d="M63.9999 72.896C64.8482 72.896 65.5359 72.2083 65.5359 71.36C65.5359 70.5117 64.8482 69.824 63.9999 69.824C63.1516 69.824 62.4639 70.5117 62.4639 71.36C62.4639 72.2083 63.1516 72.896 63.9999 72.896Z"
            fill="white"
          />
          <path
            d="M63.9999 75.9679C64.8482 75.9679 65.5359 75.2802 65.5359 74.4319C65.5359 73.5836 64.8482 72.8959 63.9999 72.8959C63.1516 72.8959 62.4639 73.5836 62.4639 74.4319C62.4639 75.2802 63.1516 75.9679 63.9999 75.9679Z"
            fill="white"
          />
          <path
            d="M63.9999 85.1839C64.8482 85.1839 65.5359 84.4962 65.5359 83.6479C65.5359 82.7996 64.8482 82.1119 63.9999 82.1119C63.1516 82.1119 62.4639 82.7996 62.4639 83.6479C62.4639 84.4962 63.1516 85.1839 63.9999 85.1839Z"
            fill="white"
          />
          <path
            d="M63.9999 91.328C64.8482 91.328 65.5359 90.6403 65.5359 89.792C65.5359 88.9437 64.8482 88.256 63.9999 88.256C63.1516 88.256 62.4639 88.9437 62.4639 89.792C62.4639 90.6403 63.1516 91.328 63.9999 91.328Z"
            fill="white"
          />
          <path
            d="M63.9999 100.544C64.8482 100.544 65.5359 99.8562 65.5359 99.0079C65.5359 98.1596 64.8482 97.4719 63.9999 97.4719C63.1516 97.4719 62.4639 98.1596 62.4639 99.0079C62.4639 99.8562 63.1516 100.544 63.9999 100.544Z"
            fill="white"
          />
          <path
            d="M67.0721 14.5279C67.9204 14.5279 68.6081 13.8402 68.6081 12.9919C68.6081 12.1436 67.9204 11.4559 67.0721 11.4559C66.2238 11.4559 65.5361 12.1436 65.5361 12.9919C65.5361 13.8402 66.2238 14.5279 67.0721 14.5279Z"
            fill="white"
          />
          <path
            d="M67.0721 17.6C67.9204 17.6 68.6081 16.9123 68.6081 16.064C68.6081 15.2156 67.9204 14.528 67.0721 14.528C66.2238 14.528 65.5361 15.2156 65.5361 16.064C65.5361 16.9123 66.2238 17.6 67.0721 17.6Z"
            fill="white"
          />
          <path
            d="M67.0721 23.7439C67.9204 23.7439 68.6081 23.0562 68.6081 22.2079C68.6081 21.3596 67.9204 20.6719 67.0721 20.6719C66.2238 20.6719 65.5361 21.3596 65.5361 22.2079C65.5361 23.0562 66.2238 23.7439 67.0721 23.7439Z"
            fill="white"
          />
          <path
            d="M67.0721 26.8159C67.9204 26.8159 68.6081 26.1282 68.6081 25.2799C68.6081 24.4316 67.9204 23.7439 67.0721 23.7439C66.2238 23.7439 65.5361 24.4316 65.5361 25.2799C65.5361 26.1282 66.2238 26.8159 67.0721 26.8159Z"
            fill="white"
          />
          <path
            d="M67.0721 36.032C67.9204 36.032 68.6081 35.3443 68.6081 34.496C68.6081 33.6477 67.9204 32.96 67.0721 32.96C66.2238 32.96 65.5361 33.6477 65.5361 34.496C65.5361 35.3443 66.2238 36.032 67.0721 36.032Z"
            fill="white"
          />
          <path
            d="M67.0721 39.104C67.9204 39.104 68.6081 38.4163 68.6081 37.568C68.6081 36.7197 67.9204 36.032 67.0721 36.032C66.2238 36.032 65.5361 36.7197 65.5361 37.568C65.5361 38.4163 66.2238 39.104 67.0721 39.104Z"
            fill="white"
          />
          <path
            d="M67.0721 42.1759C67.9204 42.1759 68.6081 41.4882 68.6081 40.6399C68.6081 39.7916 67.9204 39.1039 67.0721 39.1039C66.2238 39.1039 65.5361 39.7916 65.5361 40.6399C65.5361 41.4882 66.2238 42.1759 67.0721 42.1759Z"
            fill="white"
          />
          <path
            d="M67.0721 45.2479C67.9204 45.2479 68.6081 44.5602 68.6081 43.7119C68.6081 42.8636 67.9204 42.1759 67.0721 42.1759C66.2238 42.1759 65.5361 42.8636 65.5361 43.7119C65.5361 44.5602 66.2238 45.2479 67.0721 45.2479Z"
            fill="white"
          />
          <path
            d="M67.0721 57.5359C67.9204 57.5359 68.6081 56.8482 68.6081 55.9999C68.6081 55.1516 67.9204 54.4639 67.0721 54.4639C66.2238 54.4639 65.5361 55.1516 65.5361 55.9999C65.5361 56.8482 66.2238 57.5359 67.0721 57.5359Z"
            fill="white"
          />
          <path
            d="M67.0721 63.6799C67.9204 63.6799 68.6081 62.9922 68.6081 62.1439C68.6081 61.2956 67.9204 60.6079 67.0721 60.6079C66.2238 60.6079 65.5361 61.2956 65.5361 62.1439C65.5361 62.9922 66.2238 63.6799 67.0721 63.6799Z"
            fill="white"
          />
          <path
            d="M67.0721 72.896C67.9204 72.896 68.6081 72.2083 68.6081 71.36C68.6081 70.5117 67.9204 69.824 67.0721 69.824C66.2238 69.824 65.5361 70.5117 65.5361 71.36C65.5361 72.2083 66.2238 72.896 67.0721 72.896Z"
            fill="white"
          />
          <path
            d="M67.0721 79.0399C67.9204 79.0399 68.6081 78.3522 68.6081 77.5039C68.6081 76.6556 67.9204 75.9679 67.0721 75.9679C66.2238 75.9679 65.5361 76.6556 65.5361 77.5039C65.5361 78.3522 66.2238 79.0399 67.0721 79.0399Z"
            fill="white"
          />
          <path
            d="M67.0721 82.1119C67.9204 82.1119 68.6081 81.4242 68.6081 80.5759C68.6081 79.7276 67.9204 79.0399 67.0721 79.0399C66.2238 79.0399 65.5361 79.7276 65.5361 80.5759C65.5361 81.4242 66.2238 82.1119 67.0721 82.1119Z"
            fill="white"
          />
          <path
            d="M67.0721 88.256C67.9204 88.256 68.6081 87.5683 68.6081 86.72C68.6081 85.8717 67.9204 85.184 67.0721 85.184C66.2238 85.184 65.5361 85.8717 65.5361 86.72C65.5361 87.5683 66.2238 88.256 67.0721 88.256Z"
            fill="white"
          />
          <path
            d="M67.0721 91.328C67.9204 91.328 68.6081 90.6403 68.6081 89.792C68.6081 88.9437 67.9204 88.256 67.0721 88.256C66.2238 88.256 65.5361 88.9437 65.5361 89.792C65.5361 90.6403 66.2238 91.328 67.0721 91.328Z"
            fill="white"
          />
          <path
            d="M67.0721 94.3999C67.9204 94.3999 68.6081 93.7122 68.6081 92.8639C68.6081 92.0156 67.9204 91.3279 67.0721 91.3279C66.2238 91.3279 65.5361 92.0156 65.5361 92.8639C65.5361 93.7122 66.2238 94.3999 67.0721 94.3999Z"
            fill="white"
          />
          <path
            d="M67.0721 97.4719C67.9204 97.4719 68.6081 96.7842 68.6081 95.9359C68.6081 95.0876 67.9204 94.3999 67.0721 94.3999C66.2238 94.3999 65.5361 95.0876 65.5361 95.9359C65.5361 96.7842 66.2238 97.4719 67.0721 97.4719Z"
            fill="white"
          />
          <path
            d="M67.0721 100.544C67.9204 100.544 68.6081 99.8562 68.6081 99.0079C68.6081 98.1596 67.9204 97.4719 67.0721 97.4719C66.2238 97.4719 65.5361 98.1596 65.5361 99.0079C65.5361 99.8562 66.2238 100.544 67.0721 100.544Z"
            fill="white"
          />
          <path
            d="M70.1439 17.6C70.9922 17.6 71.6799 16.9123 71.6799 16.064C71.6799 15.2156 70.9922 14.528 70.1439 14.528C69.2956 14.528 68.6079 15.2156 68.6079 16.064C68.6079 16.9123 69.2956 17.6 70.1439 17.6Z"
            fill="white"
          />
          <path
            d="M70.1439 23.7439C70.9922 23.7439 71.6799 23.0562 71.6799 22.2079C71.6799 21.3596 70.9922 20.6719 70.1439 20.6719C69.2956 20.6719 68.6079 21.3596 68.6079 22.2079C68.6079 23.0562 69.2956 23.7439 70.1439 23.7439Z"
            fill="white"
          />
          <path
            d="M70.1439 26.8159C70.9922 26.8159 71.6799 26.1282 71.6799 25.2799C71.6799 24.4316 70.9922 23.7439 70.1439 23.7439C69.2956 23.7439 68.6079 24.4316 68.6079 25.2799C68.6079 26.1282 69.2956 26.8159 70.1439 26.8159Z"
            fill="white"
          />
          <path
            d="M70.1439 32.9599C70.9922 32.9599 71.6799 32.2722 71.6799 31.4239C71.6799 30.5756 70.9922 29.8879 70.1439 29.8879C69.2956 29.8879 68.6079 30.5756 68.6079 31.4239C68.6079 32.2722 69.2956 32.9599 70.1439 32.9599Z"
            fill="white"
          />
          <path
            d="M70.1439 42.1759C70.9922 42.1759 71.6799 41.4882 71.6799 40.6399C71.6799 39.7916 70.9922 39.1039 70.1439 39.1039C69.2956 39.1039 68.6079 39.7916 68.6079 40.6399C68.6079 41.4882 69.2956 42.1759 70.1439 42.1759Z"
            fill="white"
          />
          <path
            d="M70.1439 45.2479C70.9922 45.2479 71.6799 44.5602 71.6799 43.7119C71.6799 42.8636 70.9922 42.1759 70.1439 42.1759C69.2956 42.1759 68.6079 42.8636 68.6079 43.7119C68.6079 44.5602 69.2956 45.2479 70.1439 45.2479Z"
            fill="white"
          />
          <path
            d="M70.1439 48.3199C70.9922 48.3199 71.6799 47.6322 71.6799 46.7839C71.6799 45.9356 70.9922 45.2479 70.1439 45.2479C69.2956 45.2479 68.6079 45.9356 68.6079 46.7839C68.6079 47.6322 69.2956 48.3199 70.1439 48.3199Z"
            fill="white"
          />
          <path
            d="M70.1439 51.3919C70.9922 51.3919 71.6799 50.7043 71.6799 49.8559C71.6799 49.0076 70.9922 48.3199 70.1439 48.3199C69.2956 48.3199 68.6079 49.0076 68.6079 49.8559C68.6079 50.7043 69.2956 51.3919 70.1439 51.3919Z"
            fill="white"
          />
          <path
            d="M70.1439 57.5359C70.9922 57.5359 71.6799 56.8482 71.6799 55.9999C71.6799 55.1516 70.9922 54.4639 70.1439 54.4639C69.2956 54.4639 68.6079 55.1516 68.6079 55.9999C68.6079 56.8482 69.2956 57.5359 70.1439 57.5359Z"
            fill="white"
          />
          <path
            d="M70.1439 66.7519C70.9922 66.7519 71.6799 66.0642 71.6799 65.2159C71.6799 64.3676 70.9922 63.6799 70.1439 63.6799C69.2956 63.6799 68.6079 64.3676 68.6079 65.2159C68.6079 66.0642 69.2956 66.7519 70.1439 66.7519Z"
            fill="white"
          />
          <path
            d="M70.1439 69.824C70.9922 69.824 71.6799 69.1363 71.6799 68.288C71.6799 67.4396 70.9922 66.752 70.1439 66.752C69.2956 66.752 68.6079 67.4396 68.6079 68.288C68.6079 69.1363 69.2956 69.824 70.1439 69.824Z"
            fill="white"
          />
          <path
            d="M70.1439 79.0399C70.9922 79.0399 71.6799 78.3522 71.6799 77.5039C71.6799 76.6556 70.9922 75.9679 70.1439 75.9679C69.2956 75.9679 68.6079 76.6556 68.6079 77.5039C68.6079 78.3522 69.2956 79.0399 70.1439 79.0399Z"
            fill="white"
          />
          <path
            d="M70.1439 85.1839C70.9922 85.1839 71.6799 84.4962 71.6799 83.6479C71.6799 82.7996 70.9922 82.1119 70.1439 82.1119C69.2956 82.1119 68.6079 82.7996 68.6079 83.6479C68.6079 84.4962 69.2956 85.1839 70.1439 85.1839Z"
            fill="white"
          />
          <path
            d="M70.1439 91.328C70.9922 91.328 71.6799 90.6403 71.6799 89.792C71.6799 88.9437 70.9922 88.256 70.1439 88.256C69.2956 88.256 68.6079 88.9437 68.6079 89.792C68.6079 90.6403 69.2956 91.328 70.1439 91.328Z"
            fill="white"
          />
          <path
            d="M70.1439 97.4719C70.9922 97.4719 71.6799 96.7842 71.6799 95.9359C71.6799 95.0876 70.9922 94.3999 70.1439 94.3999C69.2956 94.3999 68.6079 95.0876 68.6079 95.9359C68.6079 96.7842 69.2956 97.4719 70.1439 97.4719Z"
            fill="white"
          />
          <path
            d="M73.2162 14.5279C74.0645 14.5279 74.7522 13.8402 74.7522 12.9919C74.7522 12.1436 74.0645 11.4559 73.2162 11.4559C72.3679 11.4559 71.6802 12.1436 71.6802 12.9919C71.6802 13.8402 72.3679 14.5279 73.2162 14.5279Z"
            fill="white"
          />
          <path
            d="M73.2162 20.672C74.0645 20.672 74.7522 19.9843 74.7522 19.136C74.7522 18.2877 74.0645 17.6 73.2162 17.6C72.3679 17.6 71.6802 18.2877 71.6802 19.136C71.6802 19.9843 72.3679 20.672 73.2162 20.672Z"
            fill="white"
          />
          <path
            d="M73.2162 26.8159C74.0645 26.8159 74.7522 26.1282 74.7522 25.2799C74.7522 24.4316 74.0645 23.7439 73.2162 23.7439C72.3679 23.7439 71.6802 24.4316 71.6802 25.2799C71.6802 26.1282 72.3679 26.8159 73.2162 26.8159Z"
            fill="white"
          />
          <path
            d="M73.2162 45.2479C74.0645 45.2479 74.7522 44.5602 74.7522 43.7119C74.7522 42.8636 74.0645 42.1759 73.2162 42.1759C72.3679 42.1759 71.6802 42.8636 71.6802 43.7119C71.6802 44.5602 72.3679 45.2479 73.2162 45.2479Z"
            fill="white"
          />
          <path
            d="M73.2162 54.464C74.0645 54.464 74.7522 53.7763 74.7522 52.928C74.7522 52.0797 74.0645 51.392 73.2162 51.392C72.3679 51.392 71.6802 52.0797 71.6802 52.928C71.6802 53.7763 72.3679 54.464 73.2162 54.464Z"
            fill="white"
          />
          <path
            d="M73.2162 57.5359C74.0645 57.5359 74.7522 56.8482 74.7522 55.9999C74.7522 55.1516 74.0645 54.4639 73.2162 54.4639C72.3679 54.4639 71.6802 55.1516 71.6802 55.9999C71.6802 56.8482 72.3679 57.5359 73.2162 57.5359Z"
            fill="white"
          />
          <path
            d="M73.2162 72.896C74.0645 72.896 74.7522 72.2083 74.7522 71.36C74.7522 70.5117 74.0645 69.824 73.2162 69.824C72.3679 69.824 71.6802 70.5117 71.6802 71.36C71.6802 72.2083 72.3679 72.896 73.2162 72.896Z"
            fill="white"
          />
          <path
            d="M73.2162 75.9679C74.0645 75.9679 74.7522 75.2802 74.7522 74.4319C74.7522 73.5836 74.0645 72.8959 73.2162 72.8959C72.3679 72.8959 71.6802 73.5836 71.6802 74.4319C71.6802 75.2802 72.3679 75.9679 73.2162 75.9679Z"
            fill="white"
          />
          <path
            d="M73.2162 88.256C74.0645 88.256 74.7522 87.5683 74.7522 86.72C74.7522 85.8717 74.0645 85.184 73.2162 85.184C72.3679 85.184 71.6802 85.8717 71.6802 86.72C71.6802 87.5683 72.3679 88.256 73.2162 88.256Z"
            fill="white"
          />
          <path
            d="M73.2162 91.328C74.0645 91.328 74.7522 90.6403 74.7522 89.792C74.7522 88.9437 74.0645 88.256 73.2162 88.256C72.3679 88.256 71.6802 88.9437 71.6802 89.792C71.6802 90.6403 72.3679 91.328 73.2162 91.328Z"
            fill="white"
          />
          <path
            d="M73.2162 94.3999C74.0645 94.3999 74.7522 93.7122 74.7522 92.8639C74.7522 92.0156 74.0645 91.3279 73.2162 91.3279C72.3679 91.3279 71.6802 92.0156 71.6802 92.8639C71.6802 93.7122 72.3679 94.3999 73.2162 94.3999Z"
            fill="white"
          />
          <path
            d="M76.288 14.5279C77.1363 14.5279 77.824 13.8402 77.824 12.9919C77.824 12.1436 77.1363 11.4559 76.288 11.4559C75.4396 11.4559 74.752 12.1436 74.752 12.9919C74.752 13.8402 75.4396 14.5279 76.288 14.5279Z"
            fill="white"
          />
          <path
            d="M76.288 17.6C77.1363 17.6 77.824 16.9123 77.824 16.064C77.824 15.2156 77.1363 14.528 76.288 14.528C75.4396 14.528 74.752 15.2156 74.752 16.064C74.752 16.9123 75.4396 17.6 76.288 17.6Z"
            fill="white"
          />
          <path
            d="M76.288 20.672C77.1363 20.672 77.824 19.9843 77.824 19.136C77.824 18.2877 77.1363 17.6 76.288 17.6C75.4396 17.6 74.752 18.2877 74.752 19.136C74.752 19.9843 75.4396 20.672 76.288 20.672Z"
            fill="white"
          />
          <path
            d="M76.288 29.8879C77.1363 29.8879 77.824 29.2002 77.824 28.3519C77.824 27.5036 77.1363 26.8159 76.288 26.8159C75.4396 26.8159 74.752 27.5036 74.752 28.3519C74.752 29.2002 75.4396 29.8879 76.288 29.8879Z"
            fill="white"
          />
          <path
            d="M76.288 32.9599C77.1363 32.9599 77.824 32.2722 77.824 31.4239C77.824 30.5756 77.1363 29.8879 76.288 29.8879C75.4396 29.8879 74.752 30.5756 74.752 31.4239C74.752 32.2722 75.4396 32.9599 76.288 32.9599Z"
            fill="white"
          />
          <path
            d="M76.288 39.104C77.1363 39.104 77.824 38.4163 77.824 37.568C77.824 36.7197 77.1363 36.032 76.288 36.032C75.4396 36.032 74.752 36.7197 74.752 37.568C74.752 38.4163 75.4396 39.104 76.288 39.104Z"
            fill="white"
          />
          <path
            d="M76.288 48.3199C77.1363 48.3199 77.824 47.6322 77.824 46.7839C77.824 45.9356 77.1363 45.2479 76.288 45.2479C75.4396 45.2479 74.752 45.9356 74.752 46.7839C74.752 47.6322 75.4396 48.3199 76.288 48.3199Z"
            fill="white"
          />
          <path
            d="M76.288 57.5359C77.1363 57.5359 77.824 56.8482 77.824 55.9999C77.824 55.1516 77.1363 54.4639 76.288 54.4639C75.4396 54.4639 74.752 55.1516 74.752 55.9999C74.752 56.8482 75.4396 57.5359 76.288 57.5359Z"
            fill="white"
          />
          <path
            d="M76.288 63.6799C77.1363 63.6799 77.824 62.9922 77.824 62.1439C77.824 61.2956 77.1363 60.6079 76.288 60.6079C75.4396 60.6079 74.752 61.2956 74.752 62.1439C74.752 62.9922 75.4396 63.6799 76.288 63.6799Z"
            fill="white"
          />
          <path
            d="M76.288 66.7519C77.1363 66.7519 77.824 66.0642 77.824 65.2159C77.824 64.3676 77.1363 63.6799 76.288 63.6799C75.4396 63.6799 74.752 64.3676 74.752 65.2159C74.752 66.0642 75.4396 66.7519 76.288 66.7519Z"
            fill="white"
          />
          <path
            d="M76.288 72.896C77.1363 72.896 77.824 72.2083 77.824 71.36C77.824 70.5117 77.1363 69.824 76.288 69.824C75.4396 69.824 74.752 70.5117 74.752 71.36C74.752 72.2083 75.4396 72.896 76.288 72.896Z"
            fill="white"
          />
          <path
            d="M76.288 100.544C77.1363 100.544 77.824 99.8562 77.824 99.0079C77.824 98.1596 77.1363 97.4719 76.288 97.4719C75.4396 97.4719 74.752 98.1596 74.752 99.0079C74.752 99.8562 75.4396 100.544 76.288 100.544Z"
            fill="white"
          />
          <path
            d="M79.3602 14.5279C80.2085 14.5279 80.8962 13.8402 80.8962 12.9919C80.8962 12.1436 80.2085 11.4559 79.3602 11.4559C78.5119 11.4559 77.8242 12.1436 77.8242 12.9919C77.8242 13.8402 78.5119 14.5279 79.3602 14.5279Z"
            fill="white"
          />
          <path
            d="M79.3602 17.6C80.2085 17.6 80.8962 16.9123 80.8962 16.064C80.8962 15.2156 80.2085 14.528 79.3602 14.528C78.5119 14.528 77.8242 15.2156 77.8242 16.064C77.8242 16.9123 78.5119 17.6 79.3602 17.6Z"
            fill="white"
          />
          <path
            d="M79.3602 20.672C80.2085 20.672 80.8962 19.9843 80.8962 19.136C80.8962 18.2877 80.2085 17.6 79.3602 17.6C78.5119 17.6 77.8242 18.2877 77.8242 19.136C77.8242 19.9843 78.5119 20.672 79.3602 20.672Z"
            fill="white"
          />
          <path
            d="M79.3602 23.7439C80.2085 23.7439 80.8962 23.0562 80.8962 22.2079C80.8962 21.3596 80.2085 20.6719 79.3602 20.6719C78.5119 20.6719 77.8242 21.3596 77.8242 22.2079C77.8242 23.0562 78.5119 23.7439 79.3602 23.7439Z"
            fill="white"
          />
          <path
            d="M79.3602 39.104C80.2085 39.104 80.8962 38.4163 80.8962 37.568C80.8962 36.7197 80.2085 36.032 79.3602 36.032C78.5119 36.032 77.8242 36.7197 77.8242 37.568C77.8242 38.4163 78.5119 39.104 79.3602 39.104Z"
            fill="white"
          />
          <path
            d="M79.3602 54.464C80.2085 54.464 80.8962 53.7763 80.8962 52.928C80.8962 52.0797 80.2085 51.392 79.3602 51.392C78.5119 51.392 77.8242 52.0797 77.8242 52.928C77.8242 53.7763 78.5119 54.464 79.3602 54.464Z"
            fill="white"
          />
          <path
            d="M79.3602 60.6079C80.2085 60.6079 80.8962 59.9202 80.8962 59.0719C80.8962 58.2236 80.2085 57.5359 79.3602 57.5359C78.5119 57.5359 77.8242 58.2236 77.8242 59.0719C77.8242 59.9202 78.5119 60.6079 79.3602 60.6079Z"
            fill="white"
          />
          <path
            d="M79.3602 63.6799C80.2085 63.6799 80.8962 62.9922 80.8962 62.1439C80.8962 61.2956 80.2085 60.6079 79.3602 60.6079C78.5119 60.6079 77.8242 61.2956 77.8242 62.1439C77.8242 62.9922 78.5119 63.6799 79.3602 63.6799Z"
            fill="white"
          />
          <path
            d="M79.3602 72.896C80.2085 72.896 80.8962 72.2083 80.8962 71.36C80.8962 70.5117 80.2085 69.824 79.3602 69.824C78.5119 69.824 77.8242 70.5117 77.8242 71.36C77.8242 72.2083 78.5119 72.896 79.3602 72.896Z"
            fill="white"
          />
          <path
            d="M79.3602 75.9679C80.2085 75.9679 80.8962 75.2802 80.8962 74.4319C80.8962 73.5836 80.2085 72.8959 79.3602 72.8959C78.5119 72.8959 77.8242 73.5836 77.8242 74.4319C77.8242 75.2802 78.5119 75.9679 79.3602 75.9679Z"
            fill="white"
          />
          <path
            d="M79.3602 79.0399C80.2085 79.0399 80.8962 78.3522 80.8962 77.5039C80.8962 76.6556 80.2085 75.9679 79.3602 75.9679C78.5119 75.9679 77.8242 76.6556 77.8242 77.5039C77.8242 78.3522 78.5119 79.0399 79.3602 79.0399Z"
            fill="white"
          />
          <path
            d="M79.3602 82.1119C80.2085 82.1119 80.8962 81.4242 80.8962 80.5759C80.8962 79.7276 80.2085 79.0399 79.3602 79.0399C78.5119 79.0399 77.8242 79.7276 77.8242 80.5759C77.8242 81.4242 78.5119 82.1119 79.3602 82.1119Z"
            fill="white"
          />
          <path
            d="M79.3602 88.256C80.2085 88.256 80.8962 87.5683 80.8962 86.72C80.8962 85.8717 80.2085 85.184 79.3602 85.184C78.5119 85.184 77.8242 85.8717 77.8242 86.72C77.8242 87.5683 78.5119 88.256 79.3602 88.256Z"
            fill="white"
          />
          <path
            d="M79.3602 91.328C80.2085 91.328 80.8962 90.6403 80.8962 89.792C80.8962 88.9437 80.2085 88.256 79.3602 88.256C78.5119 88.256 77.8242 88.9437 77.8242 89.792C77.8242 90.6403 78.5119 91.328 79.3602 91.328Z"
            fill="white"
          />
          <path
            d="M79.3602 94.3999C80.2085 94.3999 80.8962 93.7122 80.8962 92.8639C80.8962 92.0156 80.2085 91.3279 79.3602 91.3279C78.5119 91.3279 77.8242 92.0156 77.8242 92.8639C77.8242 93.7122 78.5119 94.3999 79.3602 94.3999Z"
            fill="white"
          />
          <path
            d="M79.3602 97.4719C80.2085 97.4719 80.8962 96.7842 80.8962 95.9359C80.8962 95.0876 80.2085 94.3999 79.3602 94.3999C78.5119 94.3999 77.8242 95.0876 77.8242 95.9359C77.8242 96.7842 78.5119 97.4719 79.3602 97.4719Z"
            fill="white"
          />
          <path
            d="M79.3602 100.544C80.2085 100.544 80.8962 99.8562 80.8962 99.0079C80.8962 98.1596 80.2085 97.4719 79.3602 97.4719C78.5119 97.4719 77.8242 98.1596 77.8242 99.0079C77.8242 99.8562 78.5119 100.544 79.3602 100.544Z"
            fill="white"
          />
          <path
            d="M82.432 14.5279C83.2803 14.5279 83.968 13.8402 83.968 12.9919C83.968 12.1436 83.2803 11.4559 82.432 11.4559C81.5837 11.4559 80.896 12.1436 80.896 12.9919C80.896 13.8402 81.5837 14.5279 82.432 14.5279Z"
            fill="white"
          />
          <path
            d="M82.432 17.6C83.2803 17.6 83.968 16.9123 83.968 16.064C83.968 15.2156 83.2803 14.528 82.432 14.528C81.5837 14.528 80.896 15.2156 80.896 16.064C80.896 16.9123 81.5837 17.6 82.432 17.6Z"
            fill="white"
          />
          <path
            d="M82.432 20.672C83.2803 20.672 83.968 19.9843 83.968 19.136C83.968 18.2877 83.2803 17.6 82.432 17.6C81.5837 17.6 80.896 18.2877 80.896 19.136C80.896 19.9843 81.5837 20.672 82.432 20.672Z"
            fill="white"
          />
          <path
            d="M82.432 26.8159C83.2803 26.8159 83.968 26.1282 83.968 25.2799C83.968 24.4316 83.2803 23.7439 82.432 23.7439C81.5837 23.7439 80.896 24.4316 80.896 25.2799C80.896 26.1282 81.5837 26.8159 82.432 26.8159Z"
            fill="white"
          />
          <path
            d="M82.432 32.9599C83.2803 32.9599 83.968 32.2722 83.968 31.4239C83.968 30.5756 83.2803 29.8879 82.432 29.8879C81.5837 29.8879 80.896 30.5756 80.896 31.4239C80.896 32.2722 81.5837 32.9599 82.432 32.9599Z"
            fill="white"
          />
          <path
            d="M82.432 39.104C83.2803 39.104 83.968 38.4163 83.968 37.568C83.968 36.7197 83.2803 36.032 82.432 36.032C81.5837 36.032 80.896 36.7197 80.896 37.568C80.896 38.4163 81.5837 39.104 82.432 39.104Z"
            fill="white"
          />
          <path
            d="M82.432 42.1759C83.2803 42.1759 83.968 41.4882 83.968 40.6399C83.968 39.7916 83.2803 39.1039 82.432 39.1039C81.5837 39.1039 80.896 39.7916 80.896 40.6399C80.896 41.4882 81.5837 42.1759 82.432 42.1759Z"
            fill="white"
          />
          <path
            d="M82.432 54.464C83.2803 54.464 83.968 53.7763 83.968 52.928C83.968 52.0797 83.2803 51.392 82.432 51.392C81.5837 51.392 80.896 52.0797 80.896 52.928C80.896 53.7763 81.5837 54.464 82.432 54.464Z"
            fill="white"
          />
          <path
            d="M82.432 63.6799C83.2803 63.6799 83.968 62.9922 83.968 62.1439C83.968 61.2956 83.2803 60.6079 82.432 60.6079C81.5837 60.6079 80.896 61.2956 80.896 62.1439C80.896 62.9922 81.5837 63.6799 82.432 63.6799Z"
            fill="white"
          />
          <path
            d="M82.432 66.7519C83.2803 66.7519 83.968 66.0642 83.968 65.2159C83.968 64.3676 83.2803 63.6799 82.432 63.6799C81.5837 63.6799 80.896 64.3676 80.896 65.2159C80.896 66.0642 81.5837 66.7519 82.432 66.7519Z"
            fill="white"
          />
          <path
            d="M82.432 72.896C83.2803 72.896 83.968 72.2083 83.968 71.36C83.968 70.5117 83.2803 69.824 82.432 69.824C81.5837 69.824 80.896 70.5117 80.896 71.36C80.896 72.2083 81.5837 72.896 82.432 72.896Z"
            fill="white"
          />
          <path
            d="M82.432 75.9679C83.2803 75.9679 83.968 75.2802 83.968 74.4319C83.968 73.5836 83.2803 72.8959 82.432 72.8959C81.5837 72.8959 80.896 73.5836 80.896 74.4319C80.896 75.2802 81.5837 75.9679 82.432 75.9679Z"
            fill="white"
          />
          <path
            d="M82.432 79.0399C83.2803 79.0399 83.968 78.3522 83.968 77.5039C83.968 76.6556 83.2803 75.9679 82.432 75.9679C81.5837 75.9679 80.896 76.6556 80.896 77.5039C80.896 78.3522 81.5837 79.0399 82.432 79.0399Z"
            fill="white"
          />
          <path
            d="M82.432 82.1119C83.2803 82.1119 83.968 81.4242 83.968 80.5759C83.968 79.7276 83.2803 79.0399 82.432 79.0399C81.5837 79.0399 80.896 79.7276 80.896 80.5759C80.896 81.4242 81.5837 82.1119 82.432 82.1119Z"
            fill="white"
          />
          <path
            d="M82.432 85.1839C83.2803 85.1839 83.968 84.4962 83.968 83.6479C83.968 82.7996 83.2803 82.1119 82.432 82.1119C81.5837 82.1119 80.896 82.7996 80.896 83.6479C80.896 84.4962 81.5837 85.1839 82.432 85.1839Z"
            fill="white"
          />
          <path
            d="M82.432 88.256C83.2803 88.256 83.968 87.5683 83.968 86.72C83.968 85.8717 83.2803 85.184 82.432 85.184C81.5837 85.184 80.896 85.8717 80.896 86.72C80.896 87.5683 81.5837 88.256 82.432 88.256Z"
            fill="white"
          />
          <path
            d="M82.432 91.328C83.2803 91.328 83.968 90.6403 83.968 89.792C83.968 88.9437 83.2803 88.256 82.432 88.256C81.5837 88.256 80.896 88.9437 80.896 89.792C80.896 90.6403 81.5837 91.328 82.432 91.328Z"
            fill="white"
          />
          <path
            d="M82.432 94.3999C83.2803 94.3999 83.968 93.7122 83.968 92.8639C83.968 92.0156 83.2803 91.3279 82.432 91.3279C81.5837 91.3279 80.896 92.0156 80.896 92.8639C80.896 93.7122 81.5837 94.3999 82.432 94.3999Z"
            fill="white"
          />
          <path
            d="M82.432 97.4719C83.2803 97.4719 83.968 96.7842 83.968 95.9359C83.968 95.0876 83.2803 94.3999 82.432 94.3999C81.5837 94.3999 80.896 95.0876 80.896 95.9359C80.896 96.7842 81.5837 97.4719 82.432 97.4719Z"
            fill="white"
          />
          <path
            d="M85.5043 39.104C86.3526 39.104 87.0403 38.4163 87.0403 37.568C87.0403 36.7197 86.3526 36.032 85.5043 36.032C84.656 36.032 83.9683 36.7197 83.9683 37.568C83.9683 38.4163 84.656 39.104 85.5043 39.104Z"
            fill="white"
          />
          <path
            d="M85.5043 42.1759C86.3526 42.1759 87.0403 41.4882 87.0403 40.6399C87.0403 39.7916 86.3526 39.1039 85.5043 39.1039C84.656 39.1039 83.9683 39.7916 83.9683 40.6399C83.9683 41.4882 84.656 42.1759 85.5043 42.1759Z"
            fill="white"
          />
          <path
            d="M85.5043 48.3199C86.3526 48.3199 87.0403 47.6322 87.0403 46.7839C87.0403 45.9356 86.3526 45.2479 85.5043 45.2479C84.656 45.2479 83.9683 45.9356 83.9683 46.7839C83.9683 47.6322 84.656 48.3199 85.5043 48.3199Z"
            fill="white"
          />
          <path
            d="M85.5043 51.3919C86.3526 51.3919 87.0403 50.7043 87.0403 49.8559C87.0403 49.0076 86.3526 48.3199 85.5043 48.3199C84.656 48.3199 83.9683 49.0076 83.9683 49.8559C83.9683 50.7043 84.656 51.3919 85.5043 51.3919Z"
            fill="white"
          />
          <path
            d="M85.5043 57.5359C86.3526 57.5359 87.0403 56.8482 87.0403 55.9999C87.0403 55.1516 86.3526 54.4639 85.5043 54.4639C84.656 54.4639 83.9683 55.1516 83.9683 55.9999C83.9683 56.8482 84.656 57.5359 85.5043 57.5359Z"
            fill="white"
          />
          <path
            d="M85.5043 60.6079C86.3526 60.6079 87.0403 59.9202 87.0403 59.0719C87.0403 58.2236 86.3526 57.5359 85.5043 57.5359C84.656 57.5359 83.9683 58.2236 83.9683 59.0719C83.9683 59.9202 84.656 60.6079 85.5043 60.6079Z"
            fill="white"
          />
          <path
            d="M85.5043 66.7519C86.3526 66.7519 87.0403 66.0642 87.0403 65.2159C87.0403 64.3676 86.3526 63.6799 85.5043 63.6799C84.656 63.6799 83.9683 64.3676 83.9683 65.2159C83.9683 66.0642 84.656 66.7519 85.5043 66.7519Z"
            fill="white"
          />
          <path
            d="M85.5043 69.824C86.3526 69.824 87.0403 69.1363 87.0403 68.288C87.0403 67.4396 86.3526 66.752 85.5043 66.752C84.656 66.752 83.9683 67.4396 83.9683 68.288C83.9683 69.1363 84.656 69.824 85.5043 69.824Z"
            fill="white"
          />
          <path
            d="M85.5043 72.896C86.3526 72.896 87.0403 72.2083 87.0403 71.36C87.0403 70.5117 86.3526 69.824 85.5043 69.824C84.656 69.824 83.9683 70.5117 83.9683 71.36C83.9683 72.2083 84.656 72.896 85.5043 72.896Z"
            fill="white"
          />
          <path
            d="M85.5043 75.9679C86.3526 75.9679 87.0403 75.2802 87.0403 74.4319C87.0403 73.5836 86.3526 72.8959 85.5043 72.8959C84.656 72.8959 83.9683 73.5836 83.9683 74.4319C83.9683 75.2802 84.656 75.9679 85.5043 75.9679Z"
            fill="white"
          />
          <path
            d="M85.5043 88.256C86.3526 88.256 87.0403 87.5683 87.0403 86.72C87.0403 85.8717 86.3526 85.184 85.5043 85.184C84.656 85.184 83.9683 85.8717 83.9683 86.72C83.9683 87.5683 84.656 88.256 85.5043 88.256Z"
            fill="white"
          />
          <path
            d="M85.5043 91.328C86.3526 91.328 87.0403 90.6403 87.0403 89.792C87.0403 88.9437 86.3526 88.256 85.5043 88.256C84.656 88.256 83.9683 88.9437 83.9683 89.792C83.9683 90.6403 84.656 91.328 85.5043 91.328Z"
            fill="white"
          />
          <path
            d="M85.5043 94.3999C86.3526 94.3999 87.0403 93.7122 87.0403 92.8639C87.0403 92.0156 86.3526 91.3279 85.5043 91.3279C84.656 91.3279 83.9683 92.0156 83.9683 92.8639C83.9683 93.7122 84.656 94.3999 85.5043 94.3999Z"
            fill="white"
          />
          <path
            d="M85.5043 97.4719C86.3526 97.4719 87.0403 96.7842 87.0403 95.9359C87.0403 95.0876 86.3526 94.3999 85.5043 94.3999C84.656 94.3999 83.9683 95.0876 83.9683 95.9359C83.9683 96.7842 84.656 97.4719 85.5043 97.4719Z"
            fill="white"
          />
          <path
            d="M88.576 42.1759C89.4243 42.1759 90.112 41.4882 90.112 40.6399C90.112 39.7916 89.4243 39.1039 88.576 39.1039C87.7277 39.1039 87.04 39.7916 87.04 40.6399C87.04 41.4882 87.7277 42.1759 88.576 42.1759Z"
            fill="white"
          />
          <path
            d="M88.576 45.2479C89.4243 45.2479 90.112 44.5602 90.112 43.7119C90.112 42.8636 89.4243 42.1759 88.576 42.1759C87.7277 42.1759 87.04 42.8636 87.04 43.7119C87.04 44.5602 87.7277 45.2479 88.576 45.2479Z"
            fill="white"
          />
          <path
            d="M88.576 54.464C89.4243 54.464 90.112 53.7763 90.112 52.928C90.112 52.0797 89.4243 51.392 88.576 51.392C87.7277 51.392 87.04 52.0797 87.04 52.928C87.04 53.7763 87.7277 54.464 88.576 54.464Z"
            fill="white"
          />
          <path
            d="M88.576 57.5359C89.4243 57.5359 90.112 56.8482 90.112 55.9999C90.112 55.1516 89.4243 54.4639 88.576 54.4639C87.7277 54.4639 87.04 55.1516 87.04 55.9999C87.04 56.8482 87.7277 57.5359 88.576 57.5359Z"
            fill="white"
          />
          <path
            d="M88.576 60.6079C89.4243 60.6079 90.112 59.9202 90.112 59.0719C90.112 58.2236 89.4243 57.5359 88.576 57.5359C87.7277 57.5359 87.04 58.2236 87.04 59.0719C87.04 59.9202 87.7277 60.6079 88.576 60.6079Z"
            fill="white"
          />
          <path
            d="M88.576 72.896C89.4243 72.896 90.112 72.2083 90.112 71.36C90.112 70.5117 89.4243 69.824 88.576 69.824C87.7277 69.824 87.04 70.5117 87.04 71.36C87.04 72.2083 87.7277 72.896 88.576 72.896Z"
            fill="white"
          />
          <path
            d="M88.576 75.9679C89.4243 75.9679 90.112 75.2802 90.112 74.4319C90.112 73.5836 89.4243 72.8959 88.576 72.8959C87.7277 72.8959 87.04 73.5836 87.04 74.4319C87.04 75.2802 87.7277 75.9679 88.576 75.9679Z"
            fill="white"
          />
          <path
            d="M88.576 82.1119C89.4243 82.1119 90.112 81.4242 90.112 80.5759C90.112 79.7276 89.4243 79.0399 88.576 79.0399C87.7277 79.0399 87.04 79.7276 87.04 80.5759C87.04 81.4242 87.7277 82.1119 88.576 82.1119Z"
            fill="white"
          />
          <path
            d="M88.576 88.256C89.4243 88.256 90.112 87.5683 90.112 86.72C90.112 85.8717 89.4243 85.184 88.576 85.184C87.7277 85.184 87.04 85.8717 87.04 86.72C87.04 87.5683 87.7277 88.256 88.576 88.256Z"
            fill="white"
          />
          <path
            d="M88.576 94.3999C89.4243 94.3999 90.112 93.7122 90.112 92.8639C90.112 92.0156 89.4243 91.3279 88.576 91.3279C87.7277 91.3279 87.04 92.0156 87.04 92.8639C87.04 93.7122 87.7277 94.3999 88.576 94.3999Z"
            fill="white"
          />
          <path
            d="M91.6483 39.104C92.4966 39.104 93.1843 38.4163 93.1843 37.568C93.1843 36.7197 92.4966 36.032 91.6483 36.032C90.8 36.032 90.1123 36.7197 90.1123 37.568C90.1123 38.4163 90.8 39.104 91.6483 39.104Z"
            fill="white"
          />
          <path
            d="M91.6483 51.3919C92.4966 51.3919 93.1843 50.7043 93.1843 49.8559C93.1843 49.0076 92.4966 48.3199 91.6483 48.3199C90.8 48.3199 90.1123 49.0076 90.1123 49.8559C90.1123 50.7043 90.8 51.3919 91.6483 51.3919Z"
            fill="white"
          />
          <path
            d="M91.6483 54.464C92.4966 54.464 93.1843 53.7763 93.1843 52.928C93.1843 52.0797 92.4966 51.392 91.6483 51.392C90.8 51.392 90.1123 52.0797 90.1123 52.928C90.1123 53.7763 90.8 54.464 91.6483 54.464Z"
            fill="white"
          />
          <path
            d="M91.6483 69.824C92.4966 69.824 93.1843 69.1363 93.1843 68.288C93.1843 67.4396 92.4966 66.752 91.6483 66.752C90.8 66.752 90.1123 67.4396 90.1123 68.288C90.1123 69.1363 90.8 69.824 91.6483 69.824Z"
            fill="white"
          />
          <path
            d="M91.6483 72.896C92.4966 72.896 93.1843 72.2083 93.1843 71.36C93.1843 70.5117 92.4966 69.824 91.6483 69.824C90.8 69.824 90.1123 70.5117 90.1123 71.36C90.1123 72.2083 90.8 72.896 91.6483 72.896Z"
            fill="white"
          />
          <path
            d="M91.6483 75.9679C92.4966 75.9679 93.1843 75.2802 93.1843 74.4319C93.1843 73.5836 92.4966 72.8959 91.6483 72.8959C90.8 72.8959 90.1123 73.5836 90.1123 74.4319C90.1123 75.2802 90.8 75.9679 91.6483 75.9679Z"
            fill="white"
          />
          <path
            d="M91.6483 88.256C92.4966 88.256 93.1843 87.5683 93.1843 86.72C93.1843 85.8717 92.4966 85.184 91.6483 85.184C90.8 85.184 90.1123 85.8717 90.1123 86.72C90.1123 87.5683 90.8 88.256 91.6483 88.256Z"
            fill="white"
          />
          <path
            d="M91.6483 91.328C92.4966 91.328 93.1843 90.6403 93.1843 89.792C93.1843 88.9437 92.4966 88.256 91.6483 88.256C90.8 88.256 90.1123 88.9437 90.1123 89.792C90.1123 90.6403 90.8 91.328 91.6483 91.328Z"
            fill="white"
          />
          <path
            d="M91.6483 97.4719C92.4966 97.4719 93.1843 96.7842 93.1843 95.9359C93.1843 95.0876 92.4966 94.3999 91.6483 94.3999C90.8 94.3999 90.1123 95.0876 90.1123 95.9359C90.1123 96.7842 90.8 97.4719 91.6483 97.4719Z"
            fill="white"
          />
          <path
            d="M91.6483 100.544C92.4966 100.544 93.1843 99.8562 93.1843 99.0079C93.1843 98.1596 92.4966 97.4719 91.6483 97.4719C90.8 97.4719 90.1123 98.1596 90.1123 99.0079C90.1123 99.8562 90.8 100.544 91.6483 100.544Z"
            fill="white"
          />
          <path
            d="M94.7201 39.104C95.5684 39.104 96.2561 38.4163 96.2561 37.568C96.2561 36.7197 95.5684 36.032 94.7201 36.032C93.8718 36.032 93.1841 36.7197 93.1841 37.568C93.1841 38.4163 93.8718 39.104 94.7201 39.104Z"
            fill="white"
          />
          <path
            d="M94.7201 54.464C95.5684 54.464 96.2561 53.7763 96.2561 52.928C96.2561 52.0797 95.5684 51.392 94.7201 51.392C93.8718 51.392 93.1841 52.0797 93.1841 52.928C93.1841 53.7763 93.8718 54.464 94.7201 54.464Z"
            fill="white"
          />
          <path
            d="M94.7201 57.5359C95.5684 57.5359 96.2561 56.8482 96.2561 55.9999C96.2561 55.1516 95.5684 54.4639 94.7201 54.4639C93.8718 54.4639 93.1841 55.1516 93.1841 55.9999C93.1841 56.8482 93.8718 57.5359 94.7201 57.5359Z"
            fill="white"
          />
          <path
            d="M94.7201 60.6079C95.5684 60.6079 96.2561 59.9202 96.2561 59.0719C96.2561 58.2236 95.5684 57.5359 94.7201 57.5359C93.8718 57.5359 93.1841 58.2236 93.1841 59.0719C93.1841 59.9202 93.8718 60.6079 94.7201 60.6079Z"
            fill="white"
          />
          <path
            d="M94.7201 66.7519C95.5684 66.7519 96.2561 66.0642 96.2561 65.2159C96.2561 64.3676 95.5684 63.6799 94.7201 63.6799C93.8718 63.6799 93.1841 64.3676 93.1841 65.2159C93.1841 66.0642 93.8718 66.7519 94.7201 66.7519Z"
            fill="white"
          />
          <path
            d="M94.7201 72.896C95.5684 72.896 96.2561 72.2083 96.2561 71.36C96.2561 70.5117 95.5684 69.824 94.7201 69.824C93.8718 69.824 93.1841 70.5117 93.1841 71.36C93.1841 72.2083 93.8718 72.896 94.7201 72.896Z"
            fill="white"
          />
          <path
            d="M94.7201 75.9679C95.5684 75.9679 96.2561 75.2802 96.2561 74.4319C96.2561 73.5836 95.5684 72.8959 94.7201 72.8959C93.8718 72.8959 93.1841 73.5836 93.1841 74.4319C93.1841 75.2802 93.8718 75.9679 94.7201 75.9679Z"
            fill="white"
          />
          <path
            d="M94.7201 79.0399C95.5684 79.0399 96.2561 78.3522 96.2561 77.5039C96.2561 76.6556 95.5684 75.9679 94.7201 75.9679C93.8718 75.9679 93.1841 76.6556 93.1841 77.5039C93.1841 78.3522 93.8718 79.0399 94.7201 79.0399Z"
            fill="white"
          />
          <path
            d="M94.7201 82.1119C95.5684 82.1119 96.2561 81.4242 96.2561 80.5759C96.2561 79.7276 95.5684 79.0399 94.7201 79.0399C93.8718 79.0399 93.1841 79.7276 93.1841 80.5759C93.1841 81.4242 93.8718 82.1119 94.7201 82.1119Z"
            fill="white"
          />
          <path
            d="M94.7201 85.1839C95.5684 85.1839 96.2561 84.4962 96.2561 83.6479C96.2561 82.7996 95.5684 82.1119 94.7201 82.1119C93.8718 82.1119 93.1841 82.7996 93.1841 83.6479C93.1841 84.4962 93.8718 85.1839 94.7201 85.1839Z"
            fill="white"
          />
          <path
            d="M94.7201 88.256C95.5684 88.256 96.2561 87.5683 96.2561 86.72C96.2561 85.8717 95.5684 85.184 94.7201 85.184C93.8718 85.184 93.1841 85.8717 93.1841 86.72C93.1841 87.5683 93.8718 88.256 94.7201 88.256Z"
            fill="white"
          />
          <path
            d="M94.7201 97.4719C95.5684 97.4719 96.2561 96.7842 96.2561 95.9359C96.2561 95.0876 95.5684 94.3999 94.7201 94.3999C93.8718 94.3999 93.1841 95.0876 93.1841 95.9359C93.1841 96.7842 93.8718 97.4719 94.7201 97.4719Z"
            fill="white"
          />
          <path
            d="M97.7919 39.104C98.6402 39.104 99.3279 38.4163 99.3279 37.568C99.3279 36.7197 98.6402 36.032 97.7919 36.032C96.9435 36.032 96.2559 36.7197 96.2559 37.568C96.2559 38.4163 96.9435 39.104 97.7919 39.104Z"
            fill="white"
          />
          <path
            d="M97.7919 42.1759C98.6402 42.1759 99.3279 41.4882 99.3279 40.6399C99.3279 39.7916 98.6402 39.1039 97.7919 39.1039C96.9435 39.1039 96.2559 39.7916 96.2559 40.6399C96.2559 41.4882 96.9435 42.1759 97.7919 42.1759Z"
            fill="white"
          />
          <path
            d="M97.7919 45.2479C98.6402 45.2479 99.3279 44.5602 99.3279 43.7119C99.3279 42.8636 98.6402 42.1759 97.7919 42.1759C96.9435 42.1759 96.2559 42.8636 96.2559 43.7119C96.2559 44.5602 96.9435 45.2479 97.7919 45.2479Z"
            fill="white"
          />
          <path
            d="M97.7919 54.464C98.6402 54.464 99.3279 53.7763 99.3279 52.928C99.3279 52.0797 98.6402 51.392 97.7919 51.392C96.9435 51.392 96.2559 52.0797 96.2559 52.928C96.2559 53.7763 96.9435 54.464 97.7919 54.464Z"
            fill="white"
          />
          <path
            d="M97.7919 57.5359C98.6402 57.5359 99.3279 56.8482 99.3279 55.9999C99.3279 55.1516 98.6402 54.4639 97.7919 54.4639C96.9435 54.4639 96.2559 55.1516 96.2559 55.9999C96.2559 56.8482 96.9435 57.5359 97.7919 57.5359Z"
            fill="white"
          />
          <path
            d="M97.7919 69.824C98.6402 69.824 99.3279 69.1363 99.3279 68.288C99.3279 67.4396 98.6402 66.752 97.7919 66.752C96.9435 66.752 96.2559 67.4396 96.2559 68.288C96.2559 69.1363 96.9435 69.824 97.7919 69.824Z"
            fill="white"
          />
          <path
            d="M97.7919 85.1839C98.6402 85.1839 99.3279 84.4962 99.3279 83.6479C99.3279 82.7996 98.6402 82.1119 97.7919 82.1119C96.9435 82.1119 96.2559 82.7996 96.2559 83.6479C96.2559 84.4962 96.9435 85.1839 97.7919 85.1839Z"
            fill="white"
          />
          <path
            d="M97.7919 97.4719C98.6402 97.4719 99.3279 96.7842 99.3279 95.9359C99.3279 95.0876 98.6402 94.3999 97.7919 94.3999C96.9435 94.3999 96.2559 95.0876 96.2559 95.9359C96.2559 96.7842 96.9435 97.4719 97.7919 97.4719Z"
            fill="white"
          />
          <path
            d="M100.864 42.1759C101.712 42.1759 102.4 41.4882 102.4 40.6399C102.4 39.7916 101.712 39.1039 100.864 39.1039C100.016 39.1039 99.3281 39.7916 99.3281 40.6399C99.3281 41.4882 100.016 42.1759 100.864 42.1759Z"
            fill="white"
          />
          <path
            d="M100.864 48.3199C101.712 48.3199 102.4 47.6322 102.4 46.7839C102.4 45.9356 101.712 45.2479 100.864 45.2479C100.016 45.2479 99.3281 45.9356 99.3281 46.7839C99.3281 47.6322 100.016 48.3199 100.864 48.3199Z"
            fill="white"
          />
          <path
            d="M100.864 60.6079C101.712 60.6079 102.4 59.9202 102.4 59.0719C102.4 58.2236 101.712 57.5359 100.864 57.5359C100.016 57.5359 99.3281 58.2236 99.3281 59.0719C99.3281 59.9202 100.016 60.6079 100.864 60.6079Z"
            fill="white"
          />
          <path
            d="M100.864 75.9679C101.712 75.9679 102.4 75.2802 102.4 74.4319C102.4 73.5836 101.712 72.8959 100.864 72.8959C100.016 72.8959 99.3281 73.5836 99.3281 74.4319C99.3281 75.2802 100.016 75.9679 100.864 75.9679Z"
            fill="white"
          />
          <path
            d="M100.864 79.0399C101.712 79.0399 102.4 78.3522 102.4 77.5039C102.4 76.6556 101.712 75.9679 100.864 75.9679C100.016 75.9679 99.3281 76.6556 99.3281 77.5039C99.3281 78.3522 100.016 79.0399 100.864 79.0399Z"
            fill="white"
          />
          <path
            d="M100.864 85.1839C101.712 85.1839 102.4 84.4962 102.4 83.6479C102.4 82.7996 101.712 82.1119 100.864 82.1119C100.016 82.1119 99.3281 82.7996 99.3281 83.6479C99.3281 84.4962 100.016 85.1839 100.864 85.1839Z"
            fill="white"
          />
          <path
            d="M100.864 88.256C101.712 88.256 102.4 87.5683 102.4 86.72C102.4 85.8717 101.712 85.184 100.864 85.184C100.016 85.184 99.3281 85.8717 99.3281 86.72C99.3281 87.5683 100.016 88.256 100.864 88.256Z"
            fill="white"
          />
          <path
            d="M100.864 91.328C101.712 91.328 102.4 90.6403 102.4 89.792C102.4 88.9437 101.712 88.256 100.864 88.256C100.016 88.256 99.3281 88.9437 99.3281 89.792C99.3281 90.6403 100.016 91.328 100.864 91.328Z"
            fill="white"
          />
          <path
            d="M100.864 94.3999C101.712 94.3999 102.4 93.7122 102.4 92.8639C102.4 92.0156 101.712 91.3279 100.864 91.3279C100.016 91.3279 99.3281 92.0156 99.3281 92.8639C99.3281 93.7122 100.016 94.3999 100.864 94.3999Z"
            fill="white"
          />
          <path
            d="M100.864 97.4719C101.712 97.4719 102.4 96.7842 102.4 95.9359C102.4 95.0876 101.712 94.3999 100.864 94.3999C100.016 94.3999 99.3281 95.0876 99.3281 95.9359C99.3281 96.7842 100.016 97.4719 100.864 97.4719Z"
            fill="white"
          />
          <path
            d="M100.864 100.544C101.712 100.544 102.4 99.8562 102.4 99.0079C102.4 98.1596 101.712 97.4719 100.864 97.4719C100.016 97.4719 99.3281 98.1596 99.3281 99.0079C99.3281 99.8562 100.016 100.544 100.864 100.544Z"
            fill="white"
          />
          <path
            d="M103.936 39.104C104.784 39.104 105.472 38.4163 105.472 37.568C105.472 36.7197 104.784 36.032 103.936 36.032C103.088 36.032 102.4 36.7197 102.4 37.568C102.4 38.4163 103.088 39.104 103.936 39.104Z"
            fill="white"
          />
          <path
            d="M103.936 42.1759C104.784 42.1759 105.472 41.4882 105.472 40.6399C105.472 39.7916 104.784 39.1039 103.936 39.1039C103.088 39.1039 102.4 39.7916 102.4 40.6399C102.4 41.4882 103.088 42.1759 103.936 42.1759Z"
            fill="white"
          />
          <path
            d="M103.936 45.2479C104.784 45.2479 105.472 44.5602 105.472 43.7119C105.472 42.8636 104.784 42.1759 103.936 42.1759C103.088 42.1759 102.4 42.8636 102.4 43.7119C102.4 44.5602 103.088 45.2479 103.936 45.2479Z"
            fill="white"
          />
          <path
            d="M103.936 48.3199C104.784 48.3199 105.472 47.6322 105.472 46.7839C105.472 45.9356 104.784 45.2479 103.936 45.2479C103.088 45.2479 102.4 45.9356 102.4 46.7839C102.4 47.6322 103.088 48.3199 103.936 48.3199Z"
            fill="white"
          />
          <path
            d="M103.936 54.464C104.784 54.464 105.472 53.7763 105.472 52.928C105.472 52.0797 104.784 51.392 103.936 51.392C103.088 51.392 102.4 52.0797 102.4 52.928C102.4 53.7763 103.088 54.464 103.936 54.464Z"
            fill="white"
          />
          <path
            d="M103.936 57.5359C104.784 57.5359 105.472 56.8482 105.472 55.9999C105.472 55.1516 104.784 54.4639 103.936 54.4639C103.088 54.4639 102.4 55.1516 102.4 55.9999C102.4 56.8482 103.088 57.5359 103.936 57.5359Z"
            fill="white"
          />
          <path
            d="M103.936 63.6799C104.784 63.6799 105.472 62.9922 105.472 62.1439C105.472 61.2956 104.784 60.6079 103.936 60.6079C103.088 60.6079 102.4 61.2956 102.4 62.1439C102.4 62.9922 103.088 63.6799 103.936 63.6799Z"
            fill="white"
          />
          <path
            d="M103.936 79.0399C104.784 79.0399 105.472 78.3522 105.472 77.5039C105.472 76.6556 104.784 75.9679 103.936 75.9679C103.088 75.9679 102.4 76.6556 102.4 77.5039C102.4 78.3522 103.088 79.0399 103.936 79.0399Z"
            fill="white"
          />
          <path
            d="M103.936 88.256C104.784 88.256 105.472 87.5683 105.472 86.72C105.472 85.8717 104.784 85.184 103.936 85.184C103.088 85.184 102.4 85.8717 102.4 86.72C102.4 87.5683 103.088 88.256 103.936 88.256Z"
            fill="white"
          />
          <path
            d="M103.936 91.328C104.784 91.328 105.472 90.6403 105.472 89.792C105.472 88.9437 104.784 88.256 103.936 88.256C103.088 88.256 102.4 88.9437 102.4 89.792C102.4 90.6403 103.088 91.328 103.936 91.328Z"
            fill="white"
          />
          <path
            d="M103.936 94.3999C104.784 94.3999 105.472 93.7122 105.472 92.8639C105.472 92.0156 104.784 91.3279 103.936 91.3279C103.088 91.3279 102.4 92.0156 102.4 92.8639C102.4 93.7122 103.088 94.3999 103.936 94.3999Z"
            fill="white"
          />
          <path
            d="M103.936 100.544C104.784 100.544 105.472 99.8562 105.472 99.0079C105.472 98.1596 104.784 97.4719 103.936 97.4719C103.088 97.4719 102.4 98.1596 102.4 99.0079C102.4 99.8562 103.088 100.544 103.936 100.544Z"
            fill="white"
          />
          <path
            d="M107.008 51.3919C107.856 51.3919 108.544 50.7043 108.544 49.8559C108.544 49.0076 107.856 48.3199 107.008 48.3199C106.16 48.3199 105.472 49.0076 105.472 49.8559C105.472 50.7043 106.16 51.3919 107.008 51.3919Z"
            fill="white"
          />
          <path
            d="M107.008 54.464C107.856 54.464 108.544 53.7763 108.544 52.928C108.544 52.0797 107.856 51.392 107.008 51.392C106.16 51.392 105.472 52.0797 105.472 52.928C105.472 53.7763 106.16 54.464 107.008 54.464Z"
            fill="white"
          />
          <path
            d="M107.008 60.6079C107.856 60.6079 108.544 59.9202 108.544 59.0719C108.544 58.2236 107.856 57.5359 107.008 57.5359C106.16 57.5359 105.472 58.2236 105.472 59.0719C105.472 59.9202 106.16 60.6079 107.008 60.6079Z"
            fill="white"
          />
          <path
            d="M107.008 72.896C107.856 72.896 108.544 72.2083 108.544 71.36C108.544 70.5117 107.856 69.824 107.008 69.824C106.16 69.824 105.472 70.5117 105.472 71.36C105.472 72.2083 106.16 72.896 107.008 72.896Z"
            fill="white"
          />
          <path
            d="M107.008 75.9679C107.856 75.9679 108.544 75.2802 108.544 74.4319C108.544 73.5836 107.856 72.8959 107.008 72.8959C106.16 72.8959 105.472 73.5836 105.472 74.4319C105.472 75.2802 106.16 75.9679 107.008 75.9679Z"
            fill="white"
          />
          <path
            d="M107.008 82.1119C107.856 82.1119 108.544 81.4242 108.544 80.5759C108.544 79.7276 107.856 79.0399 107.008 79.0399C106.16 79.0399 105.472 79.7276 105.472 80.5759C105.472 81.4242 106.16 82.1119 107.008 82.1119Z"
            fill="white"
          />
          <path
            d="M107.008 85.1839C107.856 85.1839 108.544 84.4962 108.544 83.6479C108.544 82.7996 107.856 82.1119 107.008 82.1119C106.16 82.1119 105.472 82.7996 105.472 83.6479C105.472 84.4962 106.16 85.1839 107.008 85.1839Z"
            fill="white"
          />
          <path
            d="M107.008 88.256C107.856 88.256 108.544 87.5683 108.544 86.72C108.544 85.8717 107.856 85.184 107.008 85.184C106.16 85.184 105.472 85.8717 105.472 86.72C105.472 87.5683 106.16 88.256 107.008 88.256Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2082 11.4559C27.3574 11.4585 24.6244 12.5931 22.6099 14.6102C20.5955 16.6274 19.4645 19.362 19.4658 22.2127C19.4671 25.0635 20.6005 27.7971 22.6167 29.8124C24.633 31.8278 27.367 32.9599 30.2178 32.9599C33.0686 32.9599 35.8027 31.8278 37.8189 29.8124C39.8352 27.7971 40.9685 25.0635 40.9698 22.2127C40.9711 19.362 39.8402 16.6274 37.8257 14.6102C35.8113 12.5931 33.0782 11.4585 30.2274 11.4559H30.2082ZM30.2082 14.5279C32.2442 14.5305 34.1959 15.3414 35.6343 16.7824C37.0726 18.2235 37.8799 20.1767 37.8786 22.2127C37.8773 24.2488 37.0676 26.201 35.6275 27.6402C34.1874 29.0794 32.2346 29.8879 30.1986 29.8879C28.1626 29.8879 26.2099 29.0794 24.7697 27.6402C23.3296 26.201 22.5199 24.2488 22.5186 22.2127C22.5173 20.1767 23.3246 18.2235 24.763 16.7824C26.2013 15.3414 28.153 14.5305 30.189 14.5279H30.2082Z"
            fill="white"
          />
          <path
            d="M30.2081 26.816C32.753 26.816 34.8161 24.7529 34.8161 22.208C34.8161 19.663 32.753 17.6 30.2081 17.6C27.6632 17.6 25.6001 19.663 25.6001 22.208C25.6001 24.7529 27.6632 26.816 30.2081 26.816Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.544 22.208C108.541 19.3572 107.407 16.6241 105.39 14.6097C103.373 12.5952 100.638 11.4643 97.7871 11.4656C94.9364 11.4668 92.2028 12.6002 90.1874 14.6165C88.1721 16.6327 87.0399 19.3668 87.0399 22.2176C87.0399 25.0683 88.1721 27.8024 90.1874 29.8187C92.2028 31.8349 94.9364 32.9683 97.7871 32.9696C100.638 32.9708 103.373 31.8399 105.39 29.8255C107.407 27.811 108.541 25.0779 108.544 22.2272V22.208ZM105.472 22.208C105.469 24.244 104.658 26.1957 103.217 27.634C101.776 29.0724 99.8232 29.8796 97.7872 29.8784C95.7511 29.8771 93.7989 29.0674 92.3597 27.6273C90.9204 26.1871 90.112 24.2344 90.112 22.1984C90.112 20.1623 90.9204 18.2096 92.3597 16.7695C93.7989 15.3293 95.7511 14.5196 97.7872 14.5184C99.8232 14.5171 101.776 15.3244 103.217 16.7627C104.658 18.201 105.469 20.1527 105.472 22.1888V22.208Z"
            fill="white"
          />
          <path
            d="M93.1839 22.208C93.1839 24.7529 95.247 26.816 97.7919 26.816C100.337 26.816 102.4 24.7529 102.4 22.208C102.4 19.663 100.337 17.6 97.7919 17.6C95.247 17.6 93.1839 19.663 93.1839 22.208Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.4561 89.7919C19.4586 92.6427 20.5932 95.3758 22.6103 97.3902C24.6275 99.4047 27.3621 100.536 30.2128 100.534C33.0636 100.533 35.7972 99.3997 37.8126 97.3834C39.8279 95.3672 40.96 92.6331 40.96 89.7823C40.96 86.9315 39.8279 84.1974 37.8126 82.1812C35.7972 80.1649 33.0636 79.0316 30.2128 79.0303C27.3621 79.029 24.6275 80.16 22.6103 82.1744C20.5932 84.1889 19.4586 86.9219 19.4561 89.7727V89.7919ZM22.5281 89.7919C22.5306 87.7559 23.3415 85.8042 24.7826 84.3658C26.2236 82.9275 28.1768 82.1202 30.2128 82.1215C32.2489 82.1228 34.2011 82.9325 35.6403 84.3726C37.0796 85.8128 37.888 87.7655 37.888 89.8015C37.888 91.8375 37.0796 93.7902 35.6403 95.2304C34.2011 96.6705 32.2489 97.4802 30.2128 97.4815C28.1768 97.4828 26.2236 96.6755 24.7826 95.2372C23.3415 93.7988 22.5306 91.8471 22.5281 89.8111V89.7919Z"
            fill="white"
          />
          <path
            d="M34.8161 89.7919C34.8161 87.247 32.753 85.1839 30.2081 85.1839C27.6632 85.1839 25.6001 87.247 25.6001 89.7919C25.6001 92.3368 27.6632 94.3999 30.2081 94.3999C32.753 94.3999 34.8161 92.3368 34.8161 89.7919Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_455_2644)">
          <path d="M112 7.99988H16V104H112V7.99988Z" fill="#4A1EA7" />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_455_2644"
        x="0"
        y="0"
        width="128"
        height="128"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_455_2644"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_455_2644"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_455_2644">
        <rect x="16" y="8" width="96" height="96" rx="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

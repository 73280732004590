export default (props: React.ComponentProps<'svg'>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.04109 1.9594C5.65057 1.56887 5.0174 1.56887 4.62688 1.9594C4.23635 2.34992 4.23635 2.98309 4.62688 3.37361L6.04109 1.9594ZM10.6673 7.99984L11.3744 8.70694C11.7649 8.31642 11.7649 7.68325 11.3744 7.29273L10.6673 7.99984ZM4.62688 12.6261C4.23635 13.0166 4.23635 13.6498 4.62688 14.0403C5.0174 14.4308 5.65057 14.4308 6.04109 14.0403L4.62688 12.6261ZM4.62688 3.37361L9.96021 8.70694L11.3744 7.29273L6.04109 1.9594L4.62688 3.37361ZM9.96021 7.29273L4.62688 12.6261L6.04109 14.0403L11.3744 8.70694L9.96021 7.29273Z"
      fill="currentColor"
    />
  </svg>
);

export default (props: any) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3095_1024)">
      <path
        opacity="0.12"
        d="M2.41769 12.6015C2.44831 12.3259 2.46363 12.1881 2.50533 12.0592C2.54234 11.9449 2.59461 11.8362 2.66075 11.7359C2.7353 11.6228 2.83336 11.5248 3.02948 11.3286L11.8337 2.52445C12.5701 1.78807 13.764 1.78807 14.5003 2.52445C15.2367 3.26083 15.2367 4.45474 14.5003 5.19112L5.69614 13.9953C5.50002 14.1914 5.40196 14.2895 5.28892 14.364C5.18862 14.4302 5.07985 14.4824 4.96556 14.5194C4.83673 14.5612 4.6989 14.5765 4.42324 14.6071L2.16699 14.8578L2.41769 12.6015Z"
        fill="#0A0A22"
      />
      <path
        d="M2.41769 12.6015C2.44831 12.3259 2.46363 12.1881 2.50533 12.0592C2.54234 11.9449 2.59461 11.8362 2.66075 11.7359C2.7353 11.6228 2.83336 11.5248 3.02948 11.3286L11.8337 2.52445C12.5701 1.78807 13.764 1.78807 14.5003 2.52445C15.2367 3.26083 15.2367 4.45474 14.5003 5.19112L5.69614 13.9953C5.50002 14.1914 5.40196 14.2895 5.28892 14.364C5.18862 14.4302 5.07985 14.4824 4.96556 14.5194C4.83673 14.5612 4.6989 14.5765 4.42324 14.6071L2.16699 14.8578L2.41769 12.6015Z"
        stroke="#4A1EA7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3095_1024">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.5 0.524414)"
        />
      </clipPath>
    </defs>
  </svg>
);

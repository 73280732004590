import classNames from 'classnames';
import { Link } from 'react-router-dom';

export interface EntryProps {
  name: string;
  position: string;
  date: string;
  time: string;
  url: string;
}

const PrevPageArrow = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.9594 14.0406C10.3499 14.4311 10.9831 14.4311 11.3736 14.0406C11.7641 13.6501 11.7641 13.0169 11.3736 12.6264L9.9594 14.0406ZM5.33317 8.00016L4.62606 7.29306C4.23554 7.68358 4.23554 8.31675 4.62606 8.70727L5.33317 8.00016ZM11.3736 3.37394C11.7641 2.98341 11.7641 2.35025 11.3736 1.95972C10.9831 1.5692 10.3499 1.5692 9.9594 1.95972L11.3736 3.37394ZM11.3736 12.6264L6.04028 7.29306L4.62606 8.70727L9.9594 14.0406L11.3736 12.6264ZM6.04028 8.70727L11.3736 3.37394L9.9594 1.95972L4.62606 7.29306L6.04028 8.70727Z"
      fill="#4A1EA7"
    />
  </svg>
);

const NextPageArrow = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.0406 1.9594C5.65008 1.56887 5.01691 1.56887 4.62639 1.9594C4.23586 2.34992 4.23587 2.98309 4.62639 3.37361L6.0406 1.9594ZM10.6668 7.99984L11.3739 8.70694C11.7645 8.31642 11.7645 7.68325 11.3739 7.29273L10.6668 7.99984ZM4.62639 12.6261C4.23587 13.0166 4.23587 13.6498 4.62639 14.0403C5.01691 14.4308 5.65008 14.4308 6.0406 14.0403L4.62639 12.6261ZM4.62639 3.37361L9.95972 8.70694L11.3739 7.29273L6.0406 1.9594L4.62639 3.37361ZM9.95972 7.29273L4.62639 12.6261L6.0406 14.0403L11.3739 8.70694L9.95972 7.29273Z"
      fill="#4A1EA7"
    />
  </svg>
);

const Entry = ({ name, position, date, time, url }: EntryProps) => {
  return (
    <li
      className="w-full py-4 flex justify-between cursor-pointer"
      onClick={() =>
        window.open(
          `${url}/${localStorage.getItem('jwtToken') ?? ''}`,
          '_blank',
        )
      }
    >
      <div>
        <p className="font-medium text-dark text-start leading-5">{name}</p>
        <p className="font-normal text-sm text-primary text-start leading-5">
          {position}
        </p>
      </div>
      <div>
        <p className="font-light text-gray-dark text-end leading-5">{date}</p>
        <p className="font-normal text-sm text-gray-medium text-end leading-4">
          {time}
        </p>
      </div>
    </li>
  );
};

export interface AgendaCardProps {
  items?: Array<EntryProps & { id: string | number }>;
  emptyStateText: string;
  bottomSection?: React.ReactNode;

  pagination?: {
    page: number;
    nextPageLink?: string;
    prevPageLink?: string;
  };

  className?: string;
}

const AgendaCard = ({
  className,
  items = [],
  emptyStateText,
  bottomSection,
  pagination,
}: AgendaCardProps) => {
  console.log(items);
  return (
    <div
      className={classNames(
        'md:border md:border-separators w-full h-[calc(100vh_-_170px)] min-h-[400px] md:min-h-fit md:h-auto rounded-3xl md:shadow-agenda-card md:p-8 bg-white',
        'flex flex-col',
        className,
      )}
    >
      <div>
        <ul
          className={classNames(
            'flex-grow px-4 divide-y divide-separators overflow-y-auto place-items-center h-[478px] md:max-h-[380px] md:h-fit',
            items.length === 0 && 'grid place-items-center md:block',
          )}
        >
          {items.length > 0 ? (
            items.map((item) => <Entry key={item.id} {...item} />)
          ) : (
            <p className="text-gray-dark">{emptyStateText}</p>
          )}
        </ul>

        {!!pagination && !!items.length && (
          <div className="flex justify-center items-center gap-2">
            {pagination.prevPageLink ? (
              <Link to={pagination.prevPageLink}>
                <PrevPageArrow className="w-4 h-4" />
              </Link>
            ) : (
              <div className="w-4 h-4" />
            )}
            <span className="text-primary font-medium text-lg">
              {pagination.page}
            </span>
            {pagination.nextPageLink ? (
              <Link to={pagination.nextPageLink}>
                <NextPageArrow className="w-4 h-4" />
              </Link>
            ) : (
              <div className="w-4 h-4" />
            )}
          </div>
        )}
      </div>

      <div className="md:self-end">{bottomSection}</div>
    </div>
  );
};

export default AgendaCard;

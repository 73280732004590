import { ComponentProps } from 'react';

export default (props: ComponentProps<'svg'>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0C28.196 0 32 4.3877 32 16C32 27.6123 28.196 32 16 32C3.80401 32 0 27.6123 0 16C0 4.3877 3.80401 0 16 0Z"
      fill="#ECEEFD"
    />
    <path
      opacity="0.12"
      d="M10 13.8665C10 12.7464 10 12.1863 10.218 11.7585C10.4097 11.3822 10.7157 11.0762 11.092 10.8845C11.5198 10.6665 12.0799 10.6665 13.2 10.6665H18.8C19.9201 10.6665 20.4802 10.6665 20.908 10.8845C21.2843 11.0762 21.5903 11.3822 21.782 11.7585C22 12.1863 22 12.7464 22 13.8665V14.6665H10V13.8665Z"
      fill="#0A0A22"
    />
    <path
      d="M22 14.6668H10M18.6667 9.3335V12.0002M13.3333 9.3335V12.0002M13.2 22.6668H18.8C19.9201 22.6668 20.4802 22.6668 20.908 22.4488C21.2843 22.2571 21.5903 21.9511 21.782 21.5748C22 21.147 22 20.5869 22 19.4668V13.8668C22 12.7467 22 12.1867 21.782 11.7588C21.5903 11.3825 21.2843 11.0766 20.908 10.8848C20.4802 10.6668 19.9201 10.6668 18.8 10.6668H13.2C12.0799 10.6668 11.5198 10.6668 11.092 10.8848C10.7157 11.0766 10.4097 11.3825 10.218 11.7588C10 12.1867 10 12.7467 10 13.8668V19.4668C10 20.5869 10 21.147 10.218 21.5748C10.4097 21.9511 10.7157 22.2571 11.092 22.4488C11.5198 22.6668 12.0799 22.6668 13.2 22.6668Z"
      stroke="#4A1EA7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import LogoContainer from '../../components/LogoContainer';
import AgendaContainer from '../../containers/Agenda/AgendaContainer';
import { usePatient } from '../../containers/usePatient';

const Agenda = () => {
  const { isProfileComplete } = usePatient();

  return (
    <main className="">
      <LogoContainer hideButton isProfileComplete={isProfileComplete} />

      <AgendaContainer />
    </main>
  );
};

export default Agenda;

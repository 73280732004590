import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import api from './api';
import { config } from './config/config';
import RoutesIndex from './routes';
import { useNavigate } from 'react-router-dom';
import { startErrorLog } from './utils/handlingErrors';
import './i18n';
import PortalProvider from './components/Portal';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from './config/react-query.config';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    startErrorLog();
  });

  useEffect(() => {
    if (!localStorage.getItem('sessionId')) {
      const sessionId = uuidv4();
      localStorage.setItem('sessionId', sessionId);
    }

    if (localStorage.getItem('sessionId')) {
      api.register({
        sessionId: localStorage.getItem('sessionId'),
      });
    }
  }, []);

  useEffect(() => {
    if (navigate) {
      if (
        localStorage.getItem('sessionId') &&
        localStorage.getItem('jwtToken')
      ) {
        return;
      } else {
        navigate('/');
      }
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId={config.services.google.clientId}>
      <PortalProvider>
        <QueryClientProvider client={queryClient}>
          <RoutesIndex />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </PortalProvider>
    </GoogleOAuthProvider>
  );
}

export default App;

import { useForm } from 'react-hook-form';
import Button from '../../components/Button';
import { Phone } from '../../pages/Login';
import FormPhone, { DEFAULT_PHONE_PREFIX } from '../../components/Form/Phone';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ReturnButton from './_components/ReturnButton';

export interface PhoneFormProps {
  onSend: (phone: Phone) => void;
  onGoBack: () => void;
  onReturn: () => void;
}

const PhoneForm = ({ onSend, onReturn }: PhoneFormProps) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      prefix: DEFAULT_PHONE_PREFIX(),
      phone: '',
    },
  });
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [isValidForm, setIsValidForm] = useState(false);

  const watchFields = watch();

  const onChange = (value: any) => {
    const newData = {
      ...formData,
      phone: value?.target?.value,
    };
    setFormData(newData);
    setIsValidForm(isFormValid(newData));
  };

  const isFormValid = (data: any) => {
    return !!data.phone;
  };

  const onSubmit = ({ phone, prefix }: Phone) => {
    phone = phone?.replace(/[^0-9]/g, '');
    onSend && onSend({ phone, prefix: prefix ?? DEFAULT_PHONE_PREFIX });
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <FormPhone
        register={register}
        control={control}
        isDirty={watchFields['phone']}
        errors={errors['phone']}
        onChange={onChange}
      />

      <Button className="ml-auto mt-8" disabled={!isValidForm} type="submit">
        {t('login__phone_register_submit_button')}
      </Button>
      <ReturnButton onClick={onReturn}>
        {t('login__channel_form_back_button')}
      </ReturnButton>
    </form>
  );
};

export default PhoneForm;

import {
  AgendaLayoutCard,
  AgendaLayoutMain,
  AgendaLayoutTitle,
} from './AgendaLayout';
import ArrowRightIcon from '../../assets/icons/ArrowRightIcon';
import { useQuery } from '@tanstack/react-query';
import rqOptions from '../../rq/options';
import { EntryProps } from './AgendaCard';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

const MainContent = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const startOfToday = dayjs().startOf('day').toDate();

  const { data: appointments, isSuccess } = useQuery(
    rqOptions.appointments.getAll({
      page,
      starts_at_gte: startOfToday.toISOString(),
    }),
  );

  const toItems: Array<EntryProps & { id: string }> =
    appointments?.data.map(({ professional, speciality, appointment }) => {
      const date = new Date(appointment.starts_at);

      return {
        id: appointment.id,
        name: professional?.name,
        position: speciality?.name,
        date: dayjs(date).format('DD/MM/YY'),
        time: dayjs(date).format('HH:mm'),
        url: appointment.url,
      };
    }) ?? [];

  return (
    <AgendaLayoutMain>
      <AgendaLayoutTitle>{t('agenda_container__title')}</AgendaLayoutTitle>
      {isSuccess ? (
        <AgendaLayoutCard
          items={toItems}
          bottomSection={
            <div className="mt-9 md:mt-4 flex justify-center md:justify-end">
              <Link
                to="/consultations_history"
                className="text-primary text-sm text-end inline-flex items-center"
              >
                {t('agenda_container__consultation_link')}
                <ArrowRightIcon className="w-4 h-4 ml-2" />
              </Link>
            </div>
          }
          emptyStateText={t('agenda_container__empty_state')}
          pagination={{
            prevPageLink: page > 1 ? `/agenda?page=${page - 1}` : undefined,
            nextPageLink:
              appointments.meta.current_page < appointments.meta.last_page
                ? `/agenda?page=${page + 1}`
                : undefined,
            page,
          }}
        />
      ) : null}
    </AgendaLayoutMain>
  );
};

export default MainContent;

export default () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0C28.196 0 32 4.3877 32 16C32 27.6123 28.196 32 16 32C3.80401 32 0 27.6123 0 16C0 4.3877 3.80401 0 16 0Z"
      fill="#ECEEFD"
    />
    <path
      opacity="0.12"
      d="M16 16C17.6569 16 19 14.6569 19 13C19 11.3431 17.6569 10 16 10C14.3431 10 13 11.3431 13 13C13 14.6569 14.3431 16 16 16Z"
      fill="#0A0A22"
    />
    <path
      d="M10 21.3333C11.5572 19.6817 13.6713 18.6667 16 18.6667C18.3287 18.6667 20.4428 19.6817 22 21.3333M19 13C19 14.6569 17.6569 16 16 16C14.3431 16 13 14.6569 13 13C13 11.3431 14.3431 10 16 10C17.6569 10 19 11.3431 19 13Z"
      stroke="#4A1EA7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { useEffect, useState } from 'react';
import LogoContainer from '../components/LogoContainer';
import PlanActivated from '../containers/PlanPremium/PlanActivated';
import PlanPremiumForm from '../containers/PlanPremium/PlanPremiumForm';
import Loading from '../components/Loading';
import { usePatient } from '../containers/usePatient';

function PlanPremium() {
  const [screen, setScreen] = useState<'loading' | 'form' | 'activated'>(
    'loading',
  );
  const { profile, subscription, isProfileComplete } = usePatient();

  useEffect(() => {
    if (profile) {
      //@ts-ignore
      if (subscription && subscription?.plan) {
        setScreen('activated');
      } else {
        setScreen('form');
      }
    }
  }, [profile]);

  return (
    <div className="min-h-full h-full flex flex-col w-full">
      <LogoContainer hideButton isProfileComplete={isProfileComplete} />
      <div className="h-full flex flex-col mt-6 items-center p-6">
        {
          {
            loading: <Loading />,
            form: <PlanPremiumForm setScreen={setScreen} />,
            activated: <PlanActivated />,
          }[screen]
        }
      </div>
    </div>
  );
}

export default PlanPremium;

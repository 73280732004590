/**
 * Components to render an agenda-like layout
 *
 * @example
 * <AgendaLayout>
 *   <AgendaLayoutAside></AgendaLayoutAside>
 *   <AgendaLayoutMain>
 *     <AgendaLayoutTitle>Mi Agenda</AgendaLayoutTitle>
 *       <AgendaLayoutCard <props> />
 *   </AgendaLayoutMain>
 * </AgendaLayout>
 */

import classNames from 'classnames';
import AgendaCard, { AgendaCardProps } from './AgendaCard';

export const AgendaLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="container max-w-8xl mx-auto flex gap-4 px-2.5 md:px-6">
      {children}
    </div>
  );
};

export const AgendaLayoutAside = ({ children }: React.PropsWithChildren) => {
  return (
    <aside className="hidden md:w-[360px] md:block lg:w-[440px] py-8 shrink-0">
      {children}
    </aside>
  );
};

export const AgendaLayoutMain = ({ children }: React.PropsWithChildren) => {
  return <div className="pt-8 pb-4 w-full">{children}</div>;
};

export const AgendaLayoutTitle = ({
  children,
  className,
  ...props
}: React.ComponentProps<'h1'>) => {
  return (
    <div
      className={classNames(
        'font-medium text-lg md:text-2xl md:text-start px-5 text-dark',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export const AgendaLayoutCard = (props: AgendaCardProps) => {
  return <AgendaCard className="mt-4" {...props} />;
};

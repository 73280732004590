import { useEffect, useState } from 'react';
import LogoContainer from '../../components/LogoContainer';
import { useTranslation } from 'react-i18next';
import HeaderView from '../../components/HeaderView';
import Button from '../../components/Button';
import classnames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import countries from '../../utils/data/countries.json';
import Select from 'react-select';
import { FC } from 'react';
import api from '../../api';
import { useNavigate } from 'react-router-dom';
import countriesList from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import ptLocale from 'i18n-iso-countries/langs/pt.json';
import esLocale from 'i18n-iso-countries/langs/es.json';

export const DEFAULT_COUNTRY = () => {
  if (localStorage.getItem('i18nextLng')) {
    const lang = localStorage.getItem('i18nextLng')?.split('-')[0] || 'es';
    //@ts-ignore
    if (['pt', 'en', 'es'].includes(lang)) {
      switch (lang) {
        case 'pt':
          countriesList.registerLocale(ptLocale);
          break;
        case 'en':
          countriesList.registerLocale(enLocale);
          break;
        default:
        case 'es':
          countriesList.registerLocale(esLocale);
          break;
      }
    }
  }
  return 'es';
};

export type Phone = {
  prefix: string | any;
  number?: string;
  phone?: string;
  routes?: any;
};

const SelectCountry = () => {
  const [screen, setScreen] = useState<string>('routes');
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      country_code: DEFAULT_COUNTRY(),
    },
  });
  const [availableCountries, setAvailableCountries] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (availableCountries.length > 0) {
      return;
    }
    getCountries().then(({ data }) => {
      const countriesLang: any[] = [];
      const lang = localStorage.getItem('i18nextLng')?.split('-')[0] || 'es';
      countries.map(({ code }) => {
        const lowerCode = code.toLowerCase();
        if (data.includes(lowerCode)) {
          countriesList.getName(code, lang) &&
            countriesLang.push({
              name: countriesList.getName(code, lang),
              code: code,
            });
        }
      });
      const spain = {
        name: countriesList.getName('ES', lang),
        code: 'ES',
      };

      setAvailableCountries([spain, ...countriesLang]);
    });
  }),
    [];

  const getCountries = () => {
    return api.getCountries();
  };

  const onSubmit = ({ country_code }: { country_code: string }) => {
    localStorage.setItem('country_code', country_code.toLowerCase());
    navigate('/');
  };

  return (
    <div className="min-h-full h-full flex flex-col w-full">
      <LogoContainer setScreen={setScreen} screen={screen} />

      <div className="h-full flex flex-col justify-center items-center p-6 ">
        <HeaderView
          title={t('country__header_title')}
          subtitle={t('country__header_subtitle')}
        />
        <form
          className="w-full flex flex-col my-8 max-w-md"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Dropdown
            errors={errors}
            control={control}
            defaultValue={availableCountries[0]?.code}
            name="country_code"
            options={availableCountries.map(({ code, name }) => ({
              value: code,
              label: `${name}`,
            }))}
            placeholder={t('country__header_select_placeholder')}
          />

          <Button className="ml-auto mt-8" type="submit">
            {t('country__header_submit_button')}
          </Button>
        </form>
      </div>
    </div>
  );
};
const Dropdown: FC<any> = ({
  name,
  required,
  options,
  control,
  defaultValue,
  placeholder,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
      }}
      render={({ field: { onChange, value, ref } }) => (
        <Select
          className={classnames('Dropdown w-full')}
          styles={{
            menu: (provided) => ({ ...provided }),
            singleValue: (base) => ({ ...base }),
          }}
          placeholder={placeholder}
          menuPlacement="bottom"
          isSearchable={true}
          classNamePrefix="ReactDropdown"
          options={options}
          ref={ref}
          value={options.find((o: any) => o.value === value)}
          defaultValue={options.find((o: any) => o.value === defaultValue)}
          onChange={(selectedOption: any) => onChange(selectedOption.value)}
        />
      )}
    />
  );
};

export default SelectCountry;

export default (props: any) => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.93091 0.00019455L7.27799 4.14609H11.6372L8.11054 6.7084L9.45762 10.8543L5.93091 8.29199L2.4042 10.8543L3.75128 6.7084L0.224569 4.14609H4.58382L5.93091 0.00019455Z"
      fill="currentColor"
    />
  </svg>
);

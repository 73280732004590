export interface GoBackButtonProps extends React.PropsWithChildren {
  onClick: () => void;
}

const ReturnButton = ({ children, onClick }: GoBackButtonProps) => {
  return (
    <button
      type="button"
      className="outline-none text-gray-dark text-sm mt-3.5"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default ReturnButton;

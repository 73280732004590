interface HeaderViewProps {
  title: string;
  subtitle?: string;
}

const HeaderView = ({ title, subtitle }: HeaderViewProps) => (
  <div className="max-w-[300px] mx-auto">
    <h1 className="text-dark text-3xl mb-4 text-center font-medium">{title}</h1>
    <p className="text-gray-dark text-sm mb-6 text-center font-lighter">
      {subtitle}
    </p>
  </div>
);

export default HeaderView;

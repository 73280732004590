import { useForm } from 'react-hook-form';
import RadioButton from '../../components/Form/Radio';
import Button from '../../components/Button';
import Form from '../../components/Form';
import { useRef, useState } from 'react';
import api from '../../api';
import { config } from '../../config/config';
import ReCAPTCHA from 'react-google-recaptcha';
import FeatureFlag from '../../components/FeatureFlag';
import { useTranslation } from 'react-i18next';
import { Phone } from '../../pages/Login';
import ReturnButton from './_components/ReturnButton';

const GOOGLE_RECAPTCHA_API_KEY = config.services.google.recaptcha_api_key;
const isCaptchaEnabled = GOOGLE_RECAPTCHA_API_KEY !== 'disabled';

const GoBackSection = ({ onGoBack }: { onGoBack: () => void }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full justify-center items-center py-6 gap-8">
      <p className="text-dark text-sm">
        {t('login__channel_text')}
        <button className="ml-1 font-medium text-primary" onClick={onGoBack}>
          {t('login__channel_return_button')}
        </button>
      </p>
    </div>
  );
};

interface ChannelFormProps {
  phone: Phone;
  onSend: () => void;
  onGoBack: () => void;
  onReturn: () => void;
}

const ChannelForm = ({
  phone,
  onSend,
  onGoBack,
  onReturn,
}: ChannelFormProps) => {
  const { register, handleSubmit } = useForm();
  const [channel, setChannel] = useState<'whatsapp' | 'sms'>('sms');
  const { prefix, number } = phone;
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async () => {
    if (!number) {
      return;
    }

    try {
      let token = undefined;
      if (isCaptchaEnabled) {
        // @ts-ignore
        token = await recaptchaRef?.current.executeAsync();
      }
      await api
        .sendCode({ token, prefix, phone: number, channel })
        .then(() => onSend());
    } catch (error) {
      setError(true);
    }
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <FeatureFlag enabled={isCaptchaEnabled}>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={GOOGLE_RECAPTCHA_API_KEY}
        />
      </FeatureFlag>
      <Form.Field
        type="text"
        readOnly={true}
        label={t('login__channel_form_phone_field_label')}
        defaultValue={`${prefix} *** *** ${number?.slice(-3)}`}
        register={register}
        name="readonly_phone"
        className="mt-8"
      />
      <div className="mt-2">
        <RadioButton
          key="whatsapp"
          name="channel"
          label={t('login__channel_form_radio_whatsapp_label')}
          description={
            channel === 'whatsapp' &&
            t('login__channel_form_radio_whatsapp_description')
          }
          disabled
          value="whatsapp"
          register={register}
          checked={channel === 'whatsapp'}
          // setChecked={setChannel}
        />
      </div>
      <div className="mt-4">
        <RadioButton
          key="sms"
          name="channel"
          label={t('login__channel_form_radio_sms_label')}
          description={
            channel === 'sms' && t('login__channel_form_radio_sms_description')
          }
          value="sms"
          register={register}
          checked={channel === 'sms'}
          setChecked={setChannel}
        />
      </div>
      {error && (
        <div className="mt-4 text-sm text-negative">
          {t('login__channel_form_error')}
        </div>
      )}

      <GoBackSection onGoBack={onGoBack} />

      <Button disabled={!channel} className="ml-auto" type="submit">
        {t('login__channel_form_submit_button')}
      </Button>
      <ReturnButton onClick={onReturn}>
        {t('login__channel_form_back_button')}
      </ReturnButton>
    </form>
  );
};

export default ChannelForm;

export default (props: any) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_709_1014)">
      <path
        d="M20 11.5005H4C3.45 11.5005 3 11.9505 3 12.5005C3 13.0505 3.45 13.5005 4 13.5005H20C20.55 13.5005 21 13.0505 21 12.5005C21 11.9505 20.55 11.5005 20 11.5005ZM4 18.5005H20C20.55 18.5005 21 18.0505 21 17.5005C21 16.9505 20.55 16.5005 20 16.5005H4C3.45 16.5005 3 16.9505 3 17.5005C3 18.0505 3.45 18.5005 4 18.5005ZM20 6.50049H4C3.45 6.50049 3 6.95049 3 7.50049V7.51049C3 8.06049 3.45 8.51049 4 8.51049H20C20.55 8.51049 21 8.06049 21 7.51049V7.50049C21 6.95049 20.55 6.50049 20 6.50049Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_709_1014">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.500488)"
        />
      </clipPath>
    </defs>
  </svg>
);

import { FC, useRef } from 'react';
import classnames from 'classnames';

const RadioButton: FC<any> = ({
  name,
  checked,
  label,
  description = '',
  errors,
  isDirty,
  value,
  register,
  readOnly,
  disabled = false,
  defaultChecked = false,
  setChecked,
}) => {
  const radioRef = useRef<HTMLInputElement>();
  return (
    <label
      className={classnames(
        'p-4 cursor-pointer w-full gap-3 border rounded-lg flex flex-row',
        {
          'bg-blue-light border-primary': checked,
          'border-negative': !!errors && !!isDirty,
          'border-separators': !checked,
          'text-gray-medium opacity-70 pointer-events-none':
            disabled || readOnly,
        },
      )}
      onClick={() => {
        if (!readOnly) {
          radioRef?.current?.click();
          setChecked(value);
        }
      }}
    >
      <input
        tabIndex="-1"
        className="hidden"
        name={name}
        type="radio"
        disabled={disabled || readOnly}
        value={value}
        ref={radioRef}
        {...register(name)}
        defaultChecked={defaultChecked}
      />
      <button
        role="radio"
        aria-checked={radioRef?.current?.checked ?? false}
        className={classnames(
          'w-min rounded-full h-[min-content] place-self-center',
          checked ? 'text-primary' : 'text-gray-medium',
        )}
      >
        <svg width="16" height="16" fill="none" viewBox="0 0 16 16">
          <rect
            width="15"
            height="15"
            x="0.5"
            y="0.5"
            stroke="currentColor"
            rx="7.5"
          ></rect>
          {checked && <circle cx="8" cy="8" r="4" fill="currentColor"></circle>}
        </svg>
      </button>
      <div className="flex flex-col items-start">
        <div
          className={classnames(
            'flex',
            checked ? 'text-dark' : 'text-gray-dark',
          )}
        >
          {label}
        </div>
        {description && checked && (
          <div className="flex text-start text-gray-dark whitespace-break-spaces">
            {description}
          </div>
        )}
      </div>
    </label>
  );
};

export default RadioButton;

import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Cross from '../../assets/icons/Cross';
import FourPointStar from '../../assets/icons/FourPointStar';
import FourPointStar2 from '../../assets/icons/FourPointStar2';
import LogoIcon from '../../assets/icons/LogoIcon';
import Pencil from '../../assets/icons/Pencil';
import Star from '../../assets/icons/Star';
import AppPatientQr from '../../assets/qr/AppPatientQr';
import isMobile from '../../utils/isMobile';

const ProfileContainer = ({ profile, subscription }: any) => {
  const { t } = useTranslation();
  const isMobileView = isMobile();
  const [showAppBanner, setShowAppBanner] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const local = localStorage.getItem('closeAppBanner');
    local ? setShowAppBanner(false) : setShowAppBanner(true);
  }, []);

  const closeAppBanner = () => {
    localStorage.setItem('closeAppBanner', 'true');
    setShowAppBanner(false);
  };

  return (
    <div className="w-full flex items-center h-full flex-col gap-4">
      <div className="w-full md:w-fit md:p-8 md:rounded-xl md:border md:border-separator">
        <div className="flex flex-col w-full text-start text-dark border-b border-blue-light pb-6 ">
          <div className="flex flex-row w-[320px] sm:w-[433px] justify-between w-full items-center">
            <span className="capitalize text-lg font-medium">{`${profile.first_name} ${profile.last_name}`}</span>
            <button
              onClick={() => navigate('/profile')}
              className="rounded-full bg-blue-light p-2"
            >
              <Pencil className="w-4 h-4" />
            </button>
          </div>
          <div className="text-xs">{profile.email}</div>

          {subscription.plan && (
            <div className="flex justify-start items-center gap-1 text-xs pt-4">
              <FourPointStar2 className="w-6 h-6 text-[#FFB801]" />
              <span className="capitalize">
                {t('profile_containter__premium_activated')}
              </span>
            </div>
          )}
        </div>
        {!subscription.plan && <PremiumCouponContainer navigate={navigate} />}
      </div>
      {showAppBanner && (
        <DownloadAppContainer
          handleClose={closeAppBanner}
          isMobileView={isMobileView}
        />
      )}
    </div>
  );
};

const PremiumCouponContainer = ({ navigate }: { navigate: any }) => {
  return (
    <button
      className="flex flex-col bg-bg-dark rounded-xl px-5 py-4 items-start mt-4 relative"
      onClick={() => navigate('/plan_premium')}
    >
      <div className="absolute right-1 top-1 p-1 bg-[#FFB801] rounded-full">
        <FourPointStar className="w-5 h-5 text-white" />
      </div>
      <div className="flex flex-col w-4/5 gap-1">
        <div className="font-medium text-primary flex text-start">
          {t('profile_containter__button_coupon_title')}
        </div>
        <span className="flex text-sm text-dark text-start w-full [font-family:'Gotham_Rounded-Book',Helvetica]">
          {t('profile_containter__button_coupon_description')}
        </span>
      </div>
    </button>
  );
};

const DownloadAppContainer = ({ handleClose, isMobileView }: any) => {
  return (
    <div
      className={`flex justify-between max-w-lg min-w-fit w-full justify-center items-center bg-background rounded-2xl px-4 ${
        isMobileView ? 'py-4 gap-1' : 'flex-col pt-2.5'
      }`}
    >
      {
        <div
          className={`${
            !isMobileView && 'flex w-full justify-end'
          } text-secondary`}
        >
          <button className="flex text-sm justify-end" onClick={handleClose}>
            <Cross className="text-secondary w-5" />
          </button>
        </div>
      }
      <div className="flex flex-row w-full items-center justify-between">
        <div
          className={`flex flex-col items-between justify-between ${
            !isMobileView && 'ml-4'
          }`}
        >
          <div className="flex flex-row items-center justify-start gap-2">
            <div className="rounded-lg text-white flex justify-center items-center bg-primary w-10 h-10 min-h-[40px] min-w-[40px]">
              <LogoIcon />
            </div>
            <div className="flex flex-col text-dark text-xs items-start">
              <span>MediQuo</span>
              <div className="flex flex-row items-center">
                {[...Array(5).keys()].map((index) => (
                  <Star key={index} className="text-dark" />
                ))}
                <span className="text-xs text-gray-dark">(4,5)</span>
              </div>
            </div>
          </div>

          {!isMobileView && (
            <div className="flex flex-col my-4 items-start text-start w-full text-xs text-gray-dark">
              <span>{t('plan_premium__not_mobile_title')}</span>
              <span>{t('plan_premium__not_mobile_description')}</span>
            </div>
          )}
        </div>
        {isMobileView ? (
          <a
            className="bg-bg-dark text-primary py-2 px-4 rounded-lg uppercase text-sm tracking-widest"
            href="https://www.mediquo.com/app"
          >
            {t('plan_premium__mobile_link')}
          </a>
        ) : (
          <AppPatientQr />
        )}
      </div>
    </div>
  );
};

export default ProfileContainer;

import { useState } from 'react';
import api from '../../api';
import Form from '../../components/Form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Phone } from '../../pages/Login';
import HeaderView from '../../components/HeaderView';

export interface CodeContainerProps {
  setScreen: (screen: string) => void;
  phone: Phone;
}

function CodeContainer({ setScreen, phone }: CodeContainerProps) {
  const { prefix, number } = phone || { prefix: '', number: '' };
  const [pattern, setPattern] = useState<string>('number');
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const login = async (code: string) => {
    if (phone)
      try {
        const {
          data: { access_token: token },
        } = await api.verifyCode({
          prefix,
          number,
          code,
        });
        localStorage.setItem('jwtToken', token);
        navigate('/home');
      } catch (error) {
        console.log(error);
        setError(true);
      }
  };

  const handleChange = (event: any) => {
    if (event.target.value !== '' && event.target.value.length === 6) {
      setPattern('text');
      login(event.target.value);
    } else {
      setPattern('number');
    }
  };

  return (
    <div className="container max-w-[500px]">
      <HeaderView
        title={t('login__code_header_title')}
        subtitle={t('login__code_header_subtitle')}
      />

      <div className="mt-10">
        <Form.Field
          type="text"
          name="code"
          label={t('login__code_form_code_field_label')}
          maxLength="6"
          placeholder={t('login__code_form_code_field_label')}
          pattern={pattern}
          onChange={handleChange}
          register={() => {}}
        />
        {error && (
          <div className="mb-4 text-sm text-negative">
            {t('login__code_form_error')}
          </div>
        )}
        <div className="flex flex-col w-full justify-center items-center gap-8">
          <p className="text-dark text-sm">
            {t('login__code_form_text')}
            <button
              className="ml-1 font-medium text-primary"
              onClick={() => setScreen('phone')}
            >
              {t('login__code_form_return_button')}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}

export default CodeContainer;

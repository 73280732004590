export default (props: any) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5355 17.4498L12 13.9143L8.46447 17.4498C8.07556 17.8387 7.43916 17.8387 7.05025 17.4498C6.66134 17.0609 6.66134 16.4245 7.05025 16.0356L10.5858 12.5001L7.05025 8.96455C6.66134 8.57564 6.66134 7.93924 7.05025 7.55033C7.43916 7.16142 8.07556 7.16142 8.46447 7.55033L12 11.0859L15.5355 7.55033C15.9244 7.16142 16.5608 7.16142 16.9497 7.55033C17.3387 7.93924 17.3387 8.57564 16.9497 8.96455L13.4142 12.5001L16.9497 16.0356C17.3387 16.4245 17.3387 17.0609 16.9497 17.4498C16.5608 17.8387 15.9244 17.8387 15.5355 17.4498Z"
      fill="currentColor"
    />
  </svg>
);

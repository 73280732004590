import { AgendaLayout } from './AgendaLayout';
import MainContent from './MainContent';

const AgendaContainer = () => {
  return (
    <AgendaLayout>
      <MainContent />
    </AgendaLayout>
  );
};

export default AgendaContainer;

import es from "./es.json";
import en from "./en.json";
import pt from "./pt.json";

export const locales = {
  es: {
    translation: es,
  },
  en: {
    translation: en,
  },
  pt: {
    translation: pt,
  },
};

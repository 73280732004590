import { FC } from 'react';
import Spinner from '../Spinner';
import './index.css';

const Loading: FC = () => (
  <div className="grid w-full h-full place-content-center">
    <Spinner className="text-primary" />
  </div>
);

export default Loading;

import { useTranslation } from 'react-i18next';
import ChannelForm from './ChannelForm';
import { Phone } from '../../pages/Login';
import { SetScreen } from './RoutesContainer';
import HeaderView from '../../components/HeaderView';

interface ChannelContainerProps {
  setScreen: SetScreen;
  phone: Phone;
}

function ChannelContainer({ setScreen, phone }: ChannelContainerProps) {
  const { t } = useTranslation();

  return (
    <div className="container max-w-[500px]">
      <HeaderView
        title={t('login__channel_header_title')}
        subtitle={t('login__channel_header_subtitle')}
      />

      <div>
        <ChannelForm
          phone={phone}
          onSend={() => setScreen('code')}
          onGoBack={() => setScreen('phone')}
          onReturn={() => setScreen('routes')}
        />
      </div>
    </div>
  );
}

export default ChannelContainer;
